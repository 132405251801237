import React, { useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Close } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { errorSwal, loadingSwal, successSwal } from "utils/utils";
import PersonalDetailsInput from "components/clients/inputs/PersonalDetailsInput";
import { useEffect } from "react";
import DocumentsInput from "components/clients/inputs/DocumentsInput";
import PhoneInput from "components/clients/inputs/PhoneInput";
import EmailInput from "components/clients/inputs/EmailInput";
import AddressInput from "components/clients/inputs/AddressInput";
import CompanyInput from "components/clients/inputs/CompanyInput";
import FrequentFlyerInput from "components/clients/inputs/FrequentFlyerInput";
import { validateErrors } from "utils/validations/client/globalValidation";
import NotesInput from "components/clients/inputs/NotesInput";
import { useAuth } from "contexts/AuthContext";

const UPDATE_CLIENT = gql`
  mutation UpdateClient(
    $id: ID!
    $surname: String!
    $givenName: String!
    $birthdate: Date!
    $documents: [DocumentsInput!]!
    $phones: [PhonesInput!]!
    $isGlobal: Boolean!
    $emails: [EmailsInput!]
    $address: AddressInput
    $companies: [CompaniesInput!]
    $frequentFlyer: [FrequentFlyerInput!]
    $notes: NotesInput
  ) {
    updateClient(
      id: $id
      surname: $surname
      givenName: $givenName
      birthdate: $birthdate
      documents: $documents
      phones: $phones
      isGlobal: $isGlobal
      emails: $emails
      address: $address
      companies: $companies
      frequentFlyer: $frequentFlyer
      notes: $notes
    ) {
      id
      surname
      givenName
      birthdate
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientEditModal = ({ open, setOpen, client }) => {
  const reactSwal = useMemo(() => {
    return withReactContent(Swal);
  }, []);

  const { currentUser } = useAuth();
  const userId = currentUser.id ? currentUser.id : currentUser.uid;
  const blankError = { hasError: false, message: "" };

  const [personalDetails, setPersonalDetails] = useState({
    surname: "",
    givenName: "",
    birthdate: null,
  });

  const [personalDetailsError, setPersonalDetailsError] = useState({
    surname: blankError,
    givenName: blankError,
    birthdate: blankError,
  });

  const [documents, setDocuments] = useState([
    { docType: "", docNum: "", docCountry: "", issueDate: null, expDate: null },
  ]);

  const [documentsError, setDocumentsError] = useState([
    {
      docType: blankError,
      docNum: blankError,
      docCountry: blankError,
      issueDate: blankError,
      expDate: blankError,
    },
  ]);

  const [phones, setPhones] = useState([
    { phoneType: "", phoneCountry: "", phone: "" },
  ]);

  const [phonesError, setPhonesError] = useState([
    { phoneType: blankError, phoneCountry: blankError, phone: blankError },
  ]);

  const [emails, setEmails] = useState([{ emailType: "", email: "" }]);
  const [hasEmail, setHasEmail] = useState(false);
  const [emailsError, setEmailsError] = useState([
    { emailType: blankError, email: blankError },
  ]);

  const [address, setAddress] = useState({
    street: "",
    apt: "",
    country: "",
    state: "",
    city: "",
    district: "",
    zipCode: "",
  });

  const [addressError, setAddressError] = useState({
    street: blankError,
    apt: blankError,
    country: blankError,
    state: blankError,
    city: blankError,
    district: blankError,
    zipCode: blankError,
  });

  const [hasAddress, setHasAddress] = useState(false);

  const [companies, setCompanies] = useState([{ company: "", companyId: "" }]);

  const [companiesError, setCompaniesError] = useState([
    { company: blankError, companyId: blankError },
  ]);

  const [hasCompanyAddress, setHasCompanyAddress] = useState([false]);

  const [hasCompany, setHasCompany] = useState(false);

  const [frequentFlyer, setFrequentFlyer] = useState([
    { airline: "", code: "" },
  ]);

  const [frequentFlyerError, setFrequentFlyerError] = useState([
    { airline: blankError, code: blankError },
  ]);

  const [hasFrequentFlyer, setHasFrequentFlyer] = useState(false);

  const [notes, setNotes] = useState({ isPrivate: false, notes: "" });

  const [hasNotes, setHasNotes] = useState(false);

  const [isGlobal, setIsGlobal] = useState(false);

  const clientInfo = {
    personalDetails,
    documents,
    phones,
    emails,
    address,
    companies,
    frequentFlyer,
  };
  const clientError = {
    personalDetailsError,
    documentsError,
    phonesError,
    emailsError,
    addressError,
    companiesError,
    frequentFlyerError,
  };
  const setClientError = {
    setPersonalDetailsError,
    setDocumentsError,
    setPhonesError,
    setEmailsError,
    setAddressError,
    setCompaniesError,
    setFrequentFlyerError,
  };
  const clientFields = {
    hasEmail,
    hasAddress,
    hasCompany,
    hasCompanyAddress,
    hasFrequentFlyer,
  };

  const setIntialValues = () => {
    setPersonalDetails({
      surname: client.surname,
      givenName: client.givenName,
      birthdate: client.birthdate,
    });

    let docBlankErrors = [];

    for (let i = 1; i <= client.documents.length; i++) {
      docBlankErrors.push({
        docType: blankError,
        docNum: blankError,
        docCountry: blankError,
        issueDate: blankError,
        expDate: blankError,
      });
    }

    setDocumentsError(docBlankErrors);

    const clientDocs = client.documents.map((doc) => {
      return {
        docType: doc.docType,
        docNum: doc.docNum,
        docCountry: doc.docCountry,
        issueDate: doc.issueDate,
        expDate: doc.expDate,
      };
    });

    setDocuments(clientDocs);

    let phoneBlankErrors = [];

    for (let i = 1; i <= client.phones.length; i++) {
      phoneBlankErrors.push({
        phoneType: blankError,
        phoneCountry: blankError,
        phone: blankError,
      });
    }

    setPhonesError(phoneBlankErrors);

    const clientPhones = client.phones.map((phone) => {
      return {
        phoneType: phone.phoneType,
        phoneCountry: phone.phoneCountry,
        phone: phone.phone,
      };
    });

    setPhones(clientPhones);

    if (client.emails) {
      setHasEmail(true);
      let emailBlankErrors = [];

      for (let i = 1; i <= client.emails.length; i++) {
        emailBlankErrors.push({
          emailType: blankError,
          email: blankError,
        });
      }

      setEmailsError(emailBlankErrors);

      const clientEmails = client.emails.map((email) => {
        return {
          emailType: email.emailType,
          email: email.email,
        };
      });

      setEmails(clientEmails);
    }

    if (client.address) {
      setHasAddress(true);
      setAddress({
        street: client.address.street,
        apt: client.address.apt,
        country: client.address.country,
        state: client.address.state,
        city: client.address.city,
        district: client.address.district,
        zipCode: client.address.zipCode,
      });
    }

    if (client.companies) {
      setHasCompany(true);
      let companyBlankErrors = [];
      let hasCompanyAddressInit = [];

      for (let i = 0; i < client.companies.length; i++) {
        if (client.companies[i].address) {
          companyBlankErrors.push({
            company: blankError,
            companyId: blankError,
            address: {
              street: blankError,
              apt: blankError,
              country: blankError,
              state: blankError,
              city: blankError,
              district: blankError,
              zipCode: blankError,
            },
          });
          hasCompanyAddressInit.push(true);
        } else {
          companyBlankErrors.push({
            company: blankError,
            companyId: blankError,
          });
          hasCompanyAddressInit.push(false);
        }
      }
      setCompaniesError([...companyBlankErrors]);
      setHasCompanyAddress([...hasCompanyAddressInit]);

      const clientCompanies = client.companies.map((comp) => {
        const newComp = {
          company: comp.company,
          companyId: comp.companyId,
        };

        if (comp.address) {
          newComp.address = {
            street: comp.address.street,
            apt: comp.address.apt,
            country: comp.address.country,
            state: comp.address.state,
            city: comp.address.city,
            district: comp.address.district,
            zipCode: comp.address.zipCode,
          };
        }

        return newComp;
      });

      setCompanies(clientCompanies);
    }

    if (client.frequentFlyer) {
      setHasFrequentFlyer(true);
      let frequentFlyerBlankErrors = [];
      for (let i = 0; i < client.frequentFlyer.length; i++) {
        frequentFlyerBlankErrors.push({
          airline: blankError,
          code: blankError,
        });
      }
      setFrequentFlyerError(frequentFlyerBlankErrors);

      const clientFrequentFlyer = client.frequentFlyer.map((freqFlyer) => {
        return {
          airline: freqFlyer.airline,
          code: freqFlyer.code,
        };
      });

      setFrequentFlyer(clientFrequentFlyer);
    }

    if (client.notes) {
      setHasNotes(true);
      setNotes({
        isPrivate: client.notes.isPrivate,
        notes: client.notes.notes,
      });
    }

    setIsGlobal(client.isGlobal);
  };

  const clearValues = () => {
    setPersonalDetails({
      surname: "",
      givenName: "",
      birthdate: null,
    });

    setPersonalDetailsError({
      surname: blankError,
      givenName: blankError,
      birthdate: blankError,
    });

    setDocuments([
      {
        docType: "",
        docNum: "",
        docCountry: "",
        issueDate: null,
        expDate: null,
      },
    ]);

    setDocumentsError([
      {
        docType: blankError,
        docNum: blankError,
        docCountry: blankError,
        issueDate: blankError,
        expDate: blankError,
      },
    ]);

    setPhones([{ phoneType: "", phoneCountry: "", phone: "" }]);

    setPhonesError([
      { phoneType: blankError, phoneCountry: blankError, phone: blankError },
    ]);

    setHasEmail(false);
    setEmails([{ emailType: "", email: "" }]);
    setEmailsError([{ emailType: blankError, email: blankError }]);

    setHasAddress(false);
    setAddress({
      street: "",
      apt: "",
      country: "",
      state: "",
      city: "",
      district: "",
      zipCode: "",
    });
    setAddressError({
      street: blankError,
      apt: blankError,
      country: blankError,
      state: blankError,
      city: blankError,
      district: blankError,
      zipCode: blankError,
    });

    setHasCompany(false);
    setCompanies([{ company: "", companyId: "" }]);
    setCompaniesError([{ company: blankError, companyId: blankError }]);

    setHasFrequentFlyer(false);
    setFrequentFlyer([{ airline: "", code: "" }]);
    setFrequentFlyerError([{ airline: blankError, code: blankError }]);

    setHasNotes(false);
    setNotes({ isPrivate: false, notes: "" });

    setIsGlobal(false);
  };

  const handleClose = () => {
    clearValues();
    setOpen(false);
  };

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: async () => {
      const res = await reactSwal.fire({
        ...successSwal,
        text: "Client updated correctly.",
      });
      if (res.isConfirmed || res.isDismissed) {
        handleClose();
      }
    },
    onError: (error) => {
      console.log(error);
      reactSwal.fire(errorSwal);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateErrors(clientInfo, clientError, setClientError, clientFields)) {
      reactSwal.fire({
        ...errorSwal,
        text: "There are fields that have errors",
      });
      return;
    }
    const data = {
      id: client.id,
      ...personalDetails,
      documents,
      phones,
      isGlobal,
    };

    data.emails = hasEmail ? emails : null;
    data.address = hasAddress ? address : null;
    data.companies = hasCompany ? companies : null;
    data.frequentFlyer = hasFrequentFlyer ? frequentFlyer : null;
    data.notes = hasNotes ? notes : null;

    reactSwal.fire({ ...loadingSwal, title: "Updating Client..." });

    updateClient({ variables: { ...data } });
  };

  useEffect(() => {
    if (open) {
      setIntialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <AppBar sx={{ position: "fixed" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              EDIT CLIENT
            </Typography>
            <Button type="submit" autoFocus color="inherit">
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "1rem", marginTop: "64px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PersonalDetailsInput
                personalDetails={personalDetails}
                setPersonalDetails={setPersonalDetails}
                personalDetailsError={personalDetailsError}
                setPersonalDetailsError={setPersonalDetailsError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DocumentsInput
                documents={documents}
                setDocuments={setDocuments}
                documentsError={documentsError}
                setDocumentsError={setDocumentsError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                phones={phones}
                setPhones={setPhones}
                phonesError={phonesError}
                setPhonesError={setPhonesError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EmailInput
                emails={emails}
                setEmails={setEmails}
                hasEmail={hasEmail}
                setHasEmail={setHasEmail}
                emailsError={emailsError}
                setEmailsError={setEmailsError}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressInput
                address={address}
                setAddress={setAddress}
                hasAddress={hasAddress}
                setHasAddress={setHasAddress}
                addressError={addressError}
                setAddressError={setAddressError}
                isEdit
              />
            </Grid>
            <Grid item xs={12}>
              <CompanyInput
                companies={companies}
                setCompanies={setCompanies}
                hasCompany={hasCompany}
                setHasCompany={setHasCompany}
                hasCompanyAddress={hasCompanyAddress}
                setHasCompanyAddress={setHasCompanyAddress}
                companiesError={companiesError}
                setCompaniesError={setCompaniesError}
                isEdit
              />
            </Grid>
            <Grid item xs={12}>
              <FrequentFlyerInput
                frequentFlyer={frequentFlyer}
                setFrequentFlyer={setFrequentFlyer}
                hasFrequentFlyer={hasFrequentFlyer}
                setHasFrequentFlyer={setHasFrequentFlyer}
                frequentFlyerError={frequentFlyerError}
                setFrequentFlyerError={setFrequentFlyerError}
              />
            </Grid>
            <Grid item xs={12}>
              {(!client.notes ||
                (client.notes && !client.notes.isPrivate) ||
                (client.notes &&
                  client.notes.isPrivate &&
                  client.adminId === userId)) && (
                <NotesInput
                  notes={notes}
                  setNotes={setNotes}
                  hasNotes={hasNotes}
                  setHasNotes={setHasNotes}
                />
              )}
            </Grid>
          </Grid>
          <center style={{ marginTop: "2rem" }}>
            <FormGroup
              style={{ display: "inline-block", marginBottom: "0.8rem" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isGlobal}
                    onChange={(e) => {
                      const { checked } = e.target;
                      setIsGlobal(checked);
                    }}
                  />
                }
                label="Share client with all admins."
              />
            </FormGroup>
          </center>
        </div>
      </form>
    </Dialog>
  );
};

export default ClientEditModal;
