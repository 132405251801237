import { DesktopDatePicker } from "@mui/lab";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { RecentActors, Add, Close } from "@mui/icons-material";

import { Country } from "country-state-city";

import styles from "./DocumentsInput.module.scss";
import { validateField } from "utils/validations/client/documentsValidation";

const DocumentsInput = ({
  documents,
  setDocuments,
  documentsError,
  setDocumentsError,
}) => {
  const blankDocument = {
    docType: "",
    docNum: "",
    docCountry: "",
    issueDate: null,
    expDate: null,
  };

  const blankError = { hasError: false, message: "" };

  const blankDocumentError = {
    docType: blankError,
    docNum: blankError,
    docCountry: blankError,
    issueDate: blankError,
    expDate: blankError,
  };

  const handleChange = (e, idx) => {
    const { value, name } = e.target;

    setDocuments((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][name] = value.toUpperCase();
      return newDocs;
    });
  };

  const handleBlur = (e, idx, docType, issueDate) => {
    const { value, name } = e.target;
    validateField(setDocumentsError, name, value, idx, docType, issueDate);
  };

  const handleDateChange = (value, field, idx) => {
    if (field === "issueDate") {
      setDocuments((prevVal) => {
        const newDocs = [...prevVal];
        newDocs[idx].expDate = null;
        return newDocs;
      });

      setDocumentsError((prevVal) => {
        const newDocs = [...prevVal];
        newDocs[idx].expDate = {
          hasError: false,
          message: "",
        };
        return newDocs;
      });
    }
    setDocuments((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][field] = value;
      return newDocs;
    });
  };

  const addDoc = () => {
    setDocuments((prevVal) => {
      return [...prevVal, blankDocument];
    });
    setDocumentsError((prevVal) => {
      return [...prevVal, blankDocumentError];
    });
  };

  const deleteDoc = (idx) => {
    setDocuments((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setDocumentsError((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <RecentActors />
        </CardIcon>
        <h4 className={styles.cardTitle}>Documents</h4>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        {documents.map((doc, idx) => {
          const { docType, docNum, docCountry, issueDate, expDate } = doc;

          const {
            docType: docTypeError,
            docNum: docNumError,
            docCountry: docCountryError,
            issueDate: issueDateError,
            expDate: expDateError,
          } = documentsError[idx];

          return (
            <Card
              key={idx}
              style={{
                margin: "0px",
                textAlign: "left",
                marginTop: idx > 0 ? "1rem" : "0px",
              }}
            >
              {idx > 0 && (
                <IconButton
                  color="error"
                  size="small"
                  className={styles.closeBtn}
                  onClick={() => {
                    deleteDoc(idx);
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      error={docTypeError.hasError}
                      required
                      variant="standard"
                      fullWidth
                    >
                      <InputLabel id={`docType-label-${idx}`}>
                        Document
                      </InputLabel>
                      <Select
                        labelId={`docType-label-${idx}`}
                        id={`docType-${idx}`}
                        name="docType"
                        value={docType}
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      >
                        <MenuItem value="DNI">DNI</MenuItem>
                        <MenuItem value="PASSPORT">PASSPORT</MenuItem>
                        <MenuItem value="VISA">VISA</MenuItem>
                        <MenuItem value="CE">CE</MenuItem>
                        <MenuItem value="OTHER">OTHER</MenuItem>
                      </Select>
                      <FormHelperText>{docTypeError.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      label="Doc. N°"
                      id={`docNum-${idx}`}
                      name="docNum"
                      value={docNum}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                      onBlur={(e) => {
                        handleBlur(e, idx, docType);
                      }}
                      error={docNumError.hasError}
                      helperText={docNumError.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={docCountryError.hasError}
                      required
                      variant="standard"
                      fullWidth
                    >
                      <InputLabel id={`docCountry-label-${idx}`}>
                        Country
                      </InputLabel>
                      <Select
                        labelId={`docCountry-label-${idx}`}
                        id={`docCountry-${idx}`}
                        name="docCountry"
                        value={docCountry}
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      >
                        {Country.getAllCountries().map((country, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              value={country.name.toUpperCase()}
                            >
                              {country.name.toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{docCountryError.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      OpenPickerButtonProps={{ tabIndex: -1 }}
                      label="Issue Date"
                      value={issueDate}
                      onChange={(newValue) => {
                        handleDateChange(newValue, "issueDate", idx);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          fullWidth
                          name="issueDate"
                          helperText={issueDateError.message}
                          onBlur={(e) => {
                            handleBlur(e, idx);
                          }}
                          {...params}
                          error={issueDateError.hasError}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      OpenPickerButtonProps={{ tabIndex: -1 }}
                      label="Exp. Date"
                      value={expDate}
                      onChange={(newValue) => {
                        handleDateChange(newValue, "expDate", idx);
                      }}
                      minDate={issueDate}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          fullWidth
                          name="expDate"
                          helperText={expDateError.message}
                          onBlur={(e) => {
                            handleBlur(e, idx, "", issueDate);
                          }}
                          {...params}
                          error={expDateError.hasError}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          );
        })}

        <Button
          color="success"
          variant="contained"
          className={styles.addBtn}
          onClick={addDoc}
          tabIndex={-1}
        >
          <Add />
        </Button>
      </CardBody>
    </Card>
  );
};

export default DocumentsInput;
