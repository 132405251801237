import styles from "./utils.module.scss";
import React from "react";

import { CircularProgress } from "@mui/material";

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const loadingSwal = {
  title: "Creating Client...",
  html: (
    <div style={{ height: "5rem" }}>
      <CircularProgress
        size="4rem"
        style={{
          color: "#720000",
        }}
      />
    </div>
  ),
  width: "auto",
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  showConfirmButton: false,
  customClass: {
    container: styles.mySwal,
  },
};

export const successSwal = {
  title: "Success!",
  text: "Registered correctly.",
  icon: "success",
  confirmButtonText: "Ok",
  confirmButtonColor: "#720000",
  width: "auto",
  customClass: {
    container: styles.mySwal,
  },
};

export const errorSwal = {
  title: "Oops!",
  text: "An error ocurred. Please try again.",
  icon: "error",
  confirmButtonText: "Ok",
  confirmButtonColor: "#720000",
  width: "auto",
  customClass: {
    container: styles.mySwal,
  },
};

export const confirmationSwal = {
  title: "Are you sure?",
  text: "Information cannot be restored!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "green",
  cancelButtonColor: "#d33",
  cancelButtonText: "Cancel",
  confirmButtonText: "Yes",
  customClass: {
    container: styles.mySwal,
  },
};
