import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Home } from "@mui/icons-material";

import styles from "./AddressInput.module.scss";
import CardCheckBox from "components/card/cardCheckBox/CardCheckBox";

import { Country, State, City } from "country-state-city";
import useUpdateEffect from "hooks/useUpdateEffect";
import { validateField } from "utils/validations/client/addressValidation";

const AddressInput = ({
  address: { street, apt, country, state, city, district, zipCode },
  setAddress,
  hasAddress,
  setHasAddress,
  addressError: {
    street: streetError,
    apt: aptError,
    country: countryError,
    state: stateError,
    city: cityError,
    district: districtError,
    zipCode: zipCodeError,
  },
  setAddressError,
  isEdit,
}) => {
  const [location, setLocation] = useState({ country: "", state: "" });
  const blankError = { hasError: false, message: "" };
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "country") {
      setAddress((prevVal) => {
        return {
          ...prevVal,
          state: "",
          city: "",
        };
      });
    }
    if (name === "state") {
      setAddress((prevVal) => {
        return {
          ...prevVal,
          city: "",
        };
      });
    }
    setAddress((prevVal) => {
      return {
        ...prevVal,
        [name]: value.toUpperCase(),
      };
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(setAddressError, name, value);
  };

  const handleCheck = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setAddress({
        street: "",
        apt: "",
        country: "",
        state: "",
        city: "",
        district: "",
        zipCode: "",
      });
      setAddressError({
        street: blankError,
        apt: blankError,
        country: blankError,
        state: blankError,
        city: blankError,
        district: blankError,
        zipCode: blankError,
      });
    }

    setHasAddress(checked);
  };

  const getCountryCode = (countryName) => {
    const countryIdx = Country.getAllCountries().findIndex(
      (x) => x.name.toUpperCase() === countryName
    );
    return Country.getAllCountries()[countryIdx].isoCode;
  };

  const getStateCode = (stateName) => {
    const stateIdx = State.getStatesOfCountry(location.country).findIndex(
      (x) => x.name.toUpperCase() === stateName
    );
    return State.getStatesOfCountry(location.country)[stateIdx].isoCode;
  };

  useUpdateEffect(() => {
    if (country !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, country: getCountryCode(country) };
      });
    } else {
      setLocation((prevVal) => {
        return { ...prevVal, country: "" };
      });
    }
  }, [country]);

  useUpdateEffect(() => {
    if (state !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, state: getStateCode(state) };
      });
    } else {
      setLocation((prevVal) => {
        return { ...prevVal, state: "" };
      });
    }
  }, [state]);

  useEffect(() => {
    if (isEdit && hasAddress) {
      setLocation((prevVal) => {
        return { ...prevVal, country: getCountryCode(country) };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit && hasAddress && location.country !== "" && state !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, state: getStateCode(state) };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.country]);

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <Home />
        </CardIcon>
        <CardCheckBox checked={hasAddress} handleChange={handleCheck} />
        <h4 className={styles.cardTitle}>Address</h4>
      </CardHeader>
      <CardBody>
        <Collapse in={hasAddress}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                variant="standard"
                fullWidth
                label="Street"
                id="street"
                name="street"
                onChange={handleChange}
                value={street}
                required={hasAddress}
                error={streetError.hasError}
                helperText={streetError.message}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                variant="standard"
                fullWidth
                label="Apt, suite, etc."
                id="apt"
                name="apt"
                onChange={handleChange}
                value={apt}
                error={aptError.hasError}
                helperText={aptError.message}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl
                error={countryError.hasError}
                required={hasAddress}
                variant="standard"
                fullWidth
              >
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  name="country"
                  value={country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {Country.getAllCountries().map((country, idx) => {
                    return (
                      <MenuItem key={idx} value={country.name.toUpperCase()}>
                        {country.name.toUpperCase()}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{countryError.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl
                error={stateError.hasError}
                required={hasAddress}
                variant="standard"
                fullWidth
              >
                <InputLabel id="state-label">State/Province</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  name="state"
                  value={state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {State.getStatesOfCountry(location.country).map(
                    (state, idx) => {
                      return (
                        <MenuItem key={idx} value={state.name.toUpperCase()}>
                          {state.name.toUpperCase()}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
                <FormHelperText>{stateError.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl
                error={cityError.hasError}
                required={hasAddress}
                variant="standard"
                fullWidth
              >
                <InputLabel id="city-label">City</InputLabel>
                <Select
                  labelId="city-label"
                  id="city"
                  name="city"
                  value={city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {City.getCitiesOfState(location.country, location.state).map(
                    (city, idx) => {
                      return (
                        <MenuItem key={idx} value={city.name.toUpperCase()}>
                          {city.name.toUpperCase()}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
                <FormHelperText>{cityError.message}</FormHelperText>
              </FormControl>
            </Grid>
            {country === "PERU" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  label="District"
                  id="district"
                  name="district"
                  onChange={handleChange}
                  value={district}
                  error={districtError.hasError}
                  helperText={districtError.message}
                  onBlur={handleBlur}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={country === "PERU" ? 6 : 4}>
              <TextField
                required={hasAddress}
                autoComplete="off"
                variant="standard"
                fullWidth
                label="Zip/Postal Code"
                id="zipCode"
                name="zipCode"
                onChange={handleChange}
                value={zipCode}
                error={zipCodeError.hasError}
                helperText={zipCodeError.message}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default AddressInput;
