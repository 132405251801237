// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, NavLink, useHistory } from "react-router-dom";

import { gql, useLazyQuery } from "@apollo/client";

import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { useAuth } from "../../contexts/AuthContext";

import routes from "../../routes";
import Profile from "../../views/profile/Profile";

import logo from "../../images/logo.png";

import styles from "./Admin.module.scss";
import { Button } from "@mui/material";
import { AccountCircle, Security } from "@mui/icons-material";

import Alert from "@mui/material/Alert";
import NavCollapse from "./components/NavCollapse";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    height: "100%",
    maxHeight: "max-height: calc(var(--vh, 1vh) * 100);",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1051,
  },
  content: {
    flexGrow: 1,
    padding: "4% 3%",
    [theme.breakpoints.up("md")]: {
      padding: "2% 2%",
    },
    width: "100%",
    overflow: "auto",
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const GET_ADMIN = gql`
  query Query($id: String!) {
    admin(id: $id) {
      id
      docType
      docNum
      givenName
      surname
      phone
      email
    }
  }
`;

const Admin = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentUser, setCurrentUser, logout } = useAuth();
  const history = useHistory();

  const [getAdmin, { loading: loadingAdmin }] = useLazyQuery(GET_ADMIN, {
    onCompleted: (data) => {
      setCurrentUser((prevVal) => {
        return {
          ...prevVal,
          ...data.admin,
        };
      });
      setLoading(false);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(true);

    getAdmin({
      variables: { id: currentUser.id ? currentUser.id : currentUser.uid },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const activeRoute = (route) => {
    return window.location.href.indexOf(route) !== -1;
  };

  const getViewName = (routesList) => {
    const urlLink = window.location.href;
    const routePath = urlLink.substring(urlLink.lastIndexOf("/"));
    if (routePath === "/profile") return "MI PERFIL";
    for (let i = 0; i < routesList.length; i++) {
      if (routesList[i].collapse) {
        return getViewName(routesList[i].views);
      } else {
        if (routesList[i].layout !== "/admin") continue;
        if (
          window.location.href.indexOf(
            routesList[i].layout + routesList[i].path
          ) !== -1
        ) {
          return routesList[i].name;
        }
      }
    }
  };

  const getRoutes = (routes) => {
    return routes.map((route, idx) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      return (
        route.layout === "/admin" && (
          <Route
            key={idx}
            path={`/admin${route.path}`}
            component={route.component}
          />
        )
      );
    });
  };

  const viewProfile = () => {
    const urlLink = window.location.href;
    const routePath = urlLink.substring(urlLink.lastIndexOf("/"));
    if (routePath === "/profile") return;

    history.push("/admin/profile");
    setMobileOpen(false);
  };

  const handleLogout = async () => {
    setError(false);
    try {
      setLoading(true);
      await logout();
      history.push("/");
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const drawer = (
    <div>
      <div className={`${classes.toolbar} ${styles.toolbar}`}>
        <img className={styles.logo} src={logo} alt="logo ays" />
        <Typography variant="body1">
          {`${currentUser.givenName} ${currentUser.surname}`}
        </Typography>
        <Typography variant="body1">
          {currentUser.displayName === "ADMIN" && (
            <Security style={{ marginTop: "5px" }} />
          )}
        </Typography>
        <Hidden mdUp>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            style={{ margin: "1rem 0" }}
            onClick={viewProfile}
          >
            <AccountCircle style={{ marginRight: "5px" }} />
            Profile
          </Button>
          <Button disabled={loading} size="small" onClick={handleLogout}>
            Sign Out
          </Button>
          {error && <Alert severity="error">"Ocurrio un error."</Alert>}
        </Hidden>
      </div>
      <Divider />
      <List>
        {routes.map((route, index) => {
          if (route.collapse) {
            return (
              <NavCollapse
                key={index}
                icon={route.icon}
                name={route.name}
                views={route.views}
                activeRoute={activeRoute}
                setMobileOpen={setMobileOpen}
              />
            );
          } else {
            return (
              route.layout === "/admin" && (
                <NavLink
                  key={index}
                  to={`/admin${route.path}`}
                  className={classes.navLink}
                  onClick={() => {
                    setMobileOpen(false);
                  }}
                >
                  <ListItem selected={activeRoute(route.path)} button>
                    <ListItemIcon>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.name} />
                  </ListItem>
                </NavLink>
              )
            );
          }
        })}
      </List>
    </div>
  );

  return (
    <>
      {!loading && !loadingAdmin ? (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            style={{ zIndex: 1050 }}
            position="fixed"
            className={classes.appBar}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "2px",
                  flexGrow: "1",
                }}
                noWrap
              >
                {getViewName(routes)}
              </Typography>
              <Hidden mdDown>
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  style={{ margin: "1rem 0", marginRight: "20px" }}
                  onClick={viewProfile}
                >
                  <AccountCircle style={{ marginRight: "5px" }} />
                  Profile
                </Button>
                <Button
                  color="inherit"
                  disabled={loading}
                  size="small"
                  onClick={handleLogout}
                >
                  Sign Out
                </Button>
              </Hidden>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              {getRoutes(routes)}
              <Route path="/admin/profile" component={Profile} />
              <Redirect exact={true} from="/admin" to="/admin/view-clients" />
            </Switch>
          </main>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LinearProgress color="primary" style={{ width: "85%" }} />
        </div>
      )}
    </>
  );
};

export default Admin;
