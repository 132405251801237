import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Typography,
  CircularProgress,
  Fade,
  ClickAwayListener,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import TableToolbar from "./components/TableToolbar";
// import ActionDialog from "./components/ActionDialog";

const useStyles = makeStyles({
  table: {
    minWidth: "max-content",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "grid",
    placeItems: "center",
    borderRadius: "4px",
    zIndex: 2,
  },
});

const defaultPropGetter = () => ({});

const ReactTable = ({
  data,
  columns,
  handleRefresh,
  disableRefresh,
  error,
  loading,
  hasActions,
  handleOpenDialog,
  handleDelete,
  dialog,
  editDialog,
  getCellProps = defaultPropGetter,
  isUsersTable,
  handleRoleChange,
}) => {
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // const selectRow = (id) => {
  //   if (selectedRow === id) {
  //     setSelectedRow(null);
  //   } else {
  //     setSelectedRow(id);
  //   }
  // };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setSelectedRow(null);
        }}
      >
        <Paper
          style={{ width: "100%", position: "relative", overflow: "hidden" }}
        >
          {loading && (
            <Fade in={loading}>
              <div className={classes.overlay}>
                <CircularProgress size="2rem" style={{ marginLeft: "10px" }} />
              </div>
            </Fade>
          )}
          {dialog}
          {editDialog}
          <TableToolbar
            disableRefresh={disableRefresh}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            handleRefresh={handleRefresh}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            handleOpenDialog={handleOpenDialog}
            handleDelete={handleDelete}
            isUsersTable={isUsersTable}
            handleRoleChange={handleRoleChange}
          />
          <TableContainer style={{ maxWidth: "100%", display: "block" }}>
            {error && (
              <Typography
                variant="body2"
                style={{ marginLeft: "15px", color: "red" }}
              >
                Ocurrio un error. Intente de Nuevo.
              </Typography>
            )}
            <Table className={`${classes.table}`} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, idx) => (
                      <TableCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "400",
                          textAlign:
                            hasActions && columns.length - 1 === idx
                              ? "right"
                              : "inherit",
                        }}
                      >
                        {column.canSort ? (
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? "desc" : "asc"}
                          >
                            {column.render("Header")}
                          </TableSortLabel>
                        ) : (
                          column.render("Header")
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      selected={selectedRow === row.original.id}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, idx) => {
                        return (
                          <TableCell
                            style={{
                              fontWeight: "300",
                              textAlign:
                                hasActions && columns.length - 1 === idx
                                  ? "right"
                                  : "inherit",
                            }}
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                              },
                              getCellProps(cell),
                            ])}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={(event, newPage) => {
              gotoPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mas de ${to}`}`
            }
            labelRowsPerPage="Filas por pagina:"
          />
        </Paper>
      </ClickAwayListener>
    </>
  );
};

ReactTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  hasActions: PropTypes.bool,
};

export default ReactTable;
