import React from "react";

import { Checkbox } from "@mui/material";

import styles from "./CardCheckBox.module.scss";

const CardCheckBox = ({ checked, handleChange, ...rest }) => {
  return (
    <div className={styles.checkBoxWrapper}>
      <Checkbox
        tabIndex={-1}
        checked={checked}
        onChange={handleChange}
        className={styles.checkBox}
        {...rest}
      />
    </div>
  );
};

export default CardCheckBox;
