import React from "react";

import PropTypes from "prop-types";

import styles from "./CardIcon.module.scss";

export default function CardIcon({ className, children, color, ...rest }) {
  return (
    <div className={`${styles.cardIcon} ${className}`} {...rest}>
      {children}
    </div>
  );
}

CardIcon.propTypes = {
  className: PropTypes.string,
};
