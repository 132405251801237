import validator from "validator";

export const validateField = (setFunction, field, value) => {
  let error = {
    hasError: false,
    message: "",
  };

  if (value === "" || null) {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "surname":
      case "givenName":
        const fieldName = field === "surname" ? "Surname" : "Given Name";
        if (
          !validator.isAlpha(value, "es-ES", {
            ignore: " ",
          }) ||
          !validator.isLength(value, {
            min: 2,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid ${fieldName} is required.`,
          };
        }
        break;
      case "birthdate":
        if (new Date(value).getTime() > new Date().getTime()) {
          error = {
            hasError: true,
            message: `A valid birthdate is required.`,
          };
        }
        break;
      default:
        break;
    }
  }
  setFunction((prevVal) => {
    return {
      ...prevVal,
      [field]: error,
    };
  });
};

export const validateFieldsPersonalDetails = (
  personalDetails,
  personalDetailsError,
  setFunction
) => {
  let hasError = false;
  for (const field in personalDetails) {
    if (personalDetails[field] === "" || personalDetails[field] === null) {
      hasError = true;
      setFunction((prevVal) => {
        return {
          ...prevVal,
          [field]: {
            hasError: true,
            message: "This field is required.",
          },
        };
      });
    }
  }

  for (const field in personalDetailsError) {
    if (personalDetailsError[field].hasError) hasError = true;
  }

  return hasError;
};
