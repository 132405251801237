import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import routes from "../../routes";

import styles from "./Auth.module.scss";

const Auth = () => {
  const { currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (currentUser) history.push("/admin");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.layoutWrapper}>
    <div className={styles.blur}></div>
      <header></header>
      <main className={styles.main}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.layout === "/auth" && (
                <Route
                  key={idx}
                  path={`/auth${route.path}`}
                  component={route.component}
                />
              )
            );
          })}
          <Redirect exact={true} from="/auth" to="/auth/login" />
        </Switch>
      </main>
      <footer className={styles.footer}>
        <small>© GlaMar Travel S.A.C {new Date().getFullYear()}</small>
      </footer>
    </div>
  );
};

export default Auth;
