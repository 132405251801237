import React from "react";
import ReactDOM from "react-dom";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";

import { StylesProvider } from "@mui/styles";

import { AuthProvider } from "contexts/AuthContext";

import PrivateRoute from "components/PrivateRoute";

import Auth from "layouts/auth/Auth";
import Admin from "layouts/admin/Admin";

import "./index.scss";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GLAMAR_URI,
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#720000",
    },
    secondary: {
      main: "#00C8EB",
    },
  },
});

let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloProvider client={client}>
            <AuthProvider>
              <Router>
                <Switch>
                  <Route path="/auth" component={Auth} />
                  <PrivateRoute path="/admin" component={Admin} />
                  <Redirect exact from="/" to="/auth" />
                </Switch>
              </Router>
            </AuthProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StylesProvider>,
  document.getElementById("root")
);
