import React from "react";

import { Grid, TextField, Button, Collapse, IconButton } from "@mui/material";
import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Flight, Add, Close } from "@mui/icons-material";

import styles from "./FrequentFlyerInput.module.scss";
import CardCheckBox from "components/card/cardCheckBox/CardCheckBox";
import { validateField } from "utils/validations/client/frequentFlyerValidation";

const FrequentFlyerInput = ({
  frequentFlyer,
  setFrequentFlyer,
  hasFrequentFlyer,
  setHasFrequentFlyer,
  frequentFlyerError,
  setFrequentFlyerError,
}) => {
  const blankError = { hasError: false, message: "" };

  const handleChange = (e, idx) => {
    const { value, name } = e.target;

    setFrequentFlyer((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][name] = value.toUpperCase();
      return newDocs;
    });
  };

  const handleBlur = (e, idx) => {
    const { value, name } = e.target;
    validateField(setFrequentFlyerError, name, value, idx);
  };

  const addFrequentFlyer = () => {
    setFrequentFlyer((prevVal) => {
      return [...prevVal, { airline: "", code: "" }];
    });
    setFrequentFlyerError((prevVal) => {
      return [...prevVal, { airline: blankError, code: blankError }];
    });
  };

  const deleteFrequentFlyer = (idx) => {
    setFrequentFlyer((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setFrequentFlyerError((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
  };

  const handleCheck = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setFrequentFlyer([{ airline: "", code: "" }]);
    }

    setHasFrequentFlyer(checked);
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <Flight />
        </CardIcon>
        <CardCheckBox checked={hasFrequentFlyer} handleChange={handleCheck} />
        <h4 className={styles.cardTitle}>Frequent Flyer</h4>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        <Collapse in={hasFrequentFlyer}>
          {frequentFlyer.map((frequent, idx) => {
            const { airline, code } = frequent;
            const { airline: airlineError, code: codeError } =
              frequentFlyerError[idx];
            return (
              <Card
                key={idx}
                style={{
                  margin: "0px",
                  textAlign: "left",
                  marginTop: idx > 0 ? "1rem" : "0px",
                }}
              >
                {idx > 0 && (
                  <IconButton
                    color="error"
                    size="small"
                    className={styles.closeBtn}
                    onClick={() => {
                      deleteFrequentFlyer(idx);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required={hasFrequentFlyer}
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        label="Airline"
                        id={`airline-${idx}`}
                        name="airline"
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        value={airline}
                        error={airlineError.hasError}
                        helperText={airlineError.message}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required={hasFrequentFlyer}
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        label="Code"
                        id={`code-${idx}`}
                        name="code"
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        value={code}
                        error={codeError.hasError}
                        helperText={codeError.message}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            );
          })}
          <Button
            color="success"
            variant="contained"
            className={styles.addBtn}
            onClick={addFrequentFlyer}
          >
            <Add />
          </Button>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default FrequentFlyerInput;
