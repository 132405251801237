import { DesktopDatePicker } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import React from "react";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Person } from "@mui/icons-material";

import styles from "./PersonalDetailsInput.module.scss";
import { validateField } from "utils/validations/client/personalDetailValidation";

const PersonalDetailsInput = ({
  personalDetails: { surname, givenName, birthdate },
  setPersonalDetails,
  personalDetailsError: {
    surname: surnameError,
    givenName: givenNameError,
    birthdate: birthdateError,
  },
  setPersonalDetailsError,
}) => {
  const handleChange = (e) => {
    const { value, name } = e.target;
    setPersonalDetails((prevVal) => {
      return {
        ...prevVal,
        [name]: value.toUpperCase(),
      };
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(setPersonalDetailsError, name, value);
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <Person />
        </CardIcon>
        <h4 className={styles.cardTitle}>Personal Details</h4>
      </CardHeader>
      <CardBody>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              autoComplete="off"
              variant="standard"
              fullWidth
              label="Surname(s)"
              id="surname"
              name="surname"
              onChange={handleChange}
              value={surname}
              error={surnameError.hasError}
              helperText={surnameError.message}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              autoComplete="off"
              variant="standard"
              fullWidth
              label="Given Name(s)"
              id="givenName"
              name="givenName"
              onChange={handleChange}
              value={givenName}
              error={givenNameError.hasError}
              helperText={givenNameError.message}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <DesktopDatePicker
              OpenPickerButtonProps={{ tabIndex: -1 }}
              label="Birthdate"
              value={birthdate}
              maxDate={new Date()}
              onChange={(newValue) => {
                setPersonalDetails((prevVal) => {
                  return {
                    ...prevVal,
                    birthdate: newValue,
                  };
                });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    required
                    variant="standard"
                    fullWidth
                    helperText={birthdateError.message}
                    onBlur={handleBlur}
                    name="birthdate"
                    {...params}
                    error={birthdateError.hasError}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default PersonalDetailsInput;
