import React from "react";
import PropTypes from "prop-types";

import styles from "./CardBody.module.scss";

const CardBody = ({ children, className, ...rest }) => {
  return <div className={`${styles.cardBody} ${className}`} {...rest}>{children}</div>;
};

CardBody.propTypes = {
  className: PropTypes.string,
};

export default CardBody;
