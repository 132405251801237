import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Add, Close, ContactPhone, Language } from "@mui/icons-material";

import styles from "./PhoneInput.module.scss";

import ReactCountryFlag from "react-country-flag";
import { Country } from "country-state-city";
import MaskedInput from "components/MaskedInput";
import { validateField } from "utils/validations/client/phonesValidation";

const PhoneInput = ({ phones, setPhones, phonesError, setPhonesError }) => {
  const blankPhone = { phoneType: "", phoneCountry: "", phone: "" };

  const blankError = { hasError: false, message: "" };

  const blankPhoneError = {
    phoneType: blankError,
    phoneCountry: blankError,
    phone: blankError,
  };

  const handleChange = (e, idx) => {
    const { value, name } = e.target;

    if (name === "phoneCountry") {
      setPhones((prevVal) => {
        const newDocs = [...prevVal];
        newDocs[idx]["phone"] = "";
        return newDocs;
      });
      setPhonesError((prevVal) => {
        const newDocs = [...prevVal];
        newDocs[idx]["phone"] = blankError;
        return newDocs;
      });
    }

    setPhones((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][name] = value.toUpperCase();
      return newDocs;
    });
  };

  const handleBlur = (e, idx) => {
    const { value, name } = e.target;
    validateField(setPhonesError, name, value, idx);
  };

  const addPhone = () => {
    setPhones((prevVal) => {
      return [...prevVal, blankPhone];
    });
    setPhonesError((prevVal) => {
      return [...prevVal, blankPhoneError];
    });
  };

  const deletePhone = (idx) => {
    setPhones((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setPhonesError((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
  };

  const obtainCountryPhone = (countryCode) => {
    return Country.getCountryByCode(countryCode).phonecode;
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <ContactPhone />
        </CardIcon>
        <h4 className={styles.cardTitle}>Contact - Phone</h4>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        {phones.map((phoneDetail, idx) => {
          const { phoneType, phoneCountry, phone } = phoneDetail;
          const {
            phoneType: phoneTypeError,
            phoneCountry: phoneCountryError,
            phone: phoneError,
          } = phonesError[idx];

          return (
            <Card
              key={idx}
              style={{
                margin: "0px",
                textAlign: "left",
                marginTop: idx > 0 ? "1rem" : "0px",
              }}
            >
              {idx > 0 && (
                <IconButton
                  color="error"
                  size="small"
                  className={styles.closeBtn}
                  onClick={() => {
                    deletePhone(idx);
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <FormControl
                      error={phoneTypeError.hasError}
                      required
                      variant="standard"
                      fullWidth
                    >
                      <InputLabel id={`phoneType-label-${idx}`}>
                        Type
                      </InputLabel>
                      <Select
                        labelId={`phoneType-label-${idx}`}
                        id={`phoneType-${idx}`}
                        name="phoneType"
                        value={phoneType}
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      >
                        <MenuItem value="HOME">HOME</MenuItem>
                        <MenuItem value="CELL">CELL</MenuItem>
                        <MenuItem value="OFFICE">OFFICE</MenuItem>
                        <MenuItem value="OTHER">OTHER</MenuItem>
                      </Select>
                      <FormHelperText>{phoneTypeError.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl
                      error={phoneCountryError.hasError}
                      required
                      variant="standard"
                      fullWidth
                    >
                      <InputLabel id={`phoneCountry-label-${idx}`}>
                        <Language />
                      </InputLabel>
                      <Select
                        labelId={`phoneCountry-label-${idx}`}
                        id={`phoneCountry-${idx}`}
                        name="phoneCountry"
                        value={phoneCountry}
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      >
                        {Country.getAllCountries().map((country, idx) => {
                          return (
                            <MenuItem key={idx} value={country.isoCode}>
                              <span style={{ width: "1.7em" }}>
                                {country.isoCode}
                              </span>
                              <ReactCountryFlag
                                svg
                                countryCode={country.isoCode}
                                style={{
                                  marginLeft: "0.2em",
                                  marginBottom: "0.05em",
                                  width: "1.2em",
                                  height: "1.2em",
                                  border: "1px solid lightgray",
                                  borderRadius: "3px",
                                }}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {phoneCountryError.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MaskedInput
                      required
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      label="Phone N°"
                      id={`phone-${idx}`}
                      name="phone"
                      value={phone}
                      error={phoneError.hasError}
                      helperText={phoneError.message}
                      onBlur={(e) => {
                        handleBlur(e, idx);
                      }}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                      mask={
                        phones[idx].phoneCountry !== ""
                          ? `{${obtainCountryPhone(
                              phones[idx].phoneCountry
                            )}}0[.]0[.]0[.]0[.]0[.]0[.]0[.]0[.]0[.]0[.]0[.]0[.]0`
                          : null
                      }
                      definitions={{ ".": /\s/ }}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          );
        })}

        <Button
          color="success"
          variant="contained"
          className={styles.addBtn}
          onClick={addPhone}
          tabIndex={-1}
        >
          <Add />
        </Button>
      </CardBody>
    </Card>
  );
};

export default PhoneInput;
