import { Notes } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardCheckBox from "components/card/cardCheckBox/CardCheckBox";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import React from "react";

import styles from "./NotesInput.module.scss";

const NotesInput = ({
  notes: { isPrivate, notes },
  setNotes,
  hasNotes,
  setHasNotes,
}) => {
  const handleCheck = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setNotes({ isPrivate: false, notes: "" });
    }

    setHasNotes(checked);
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setNotes((prevVal) => {
      return { ...prevVal, notes: value };
    });
  };
  return (
    <Card>
      <CardHeader icon>
        <CardIcon>
          <Notes />
        </CardIcon>
        <CardCheckBox checked={hasNotes} handleChange={handleCheck} />
        <h4 className={styles.cardTitle}>Notes</h4>
      </CardHeader>
      <CardBody>
        <Collapse in={hasNotes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup style={{ width: "fit-content" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPrivate}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setNotes((prevVal) => {
                          return { ...prevVal, isPrivate: checked };
                        });
                      }}
                    />
                  }
                  label={<span style={{ fontWeight: 300 }}>Is Private</span>}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="notes"
                label="Notes"
                fullWidth
                multiline
                maxRows={6}
                value={notes}
                onChange={handleChange}
                variant="standard"
              />
            </Grid>
          </Grid>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default NotesInput;
