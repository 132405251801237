import validator from "validator";
import { validateFieldsAddress } from "./addressValidation";

export const validateField = (setFunction, field, value, idx) => {
  let error = {
    hasError: false,
    message: "",
  };
  if (value === "" || null) {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "company":
        if (
          !validator.isAlphanumeric(value, "es-ES", {
            ignore: " ,.-0123456789&#",
          }) ||
          !validator.isLength(value, {
            min: 1,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid Company Name is required.`,
          };
        }
        break;
      case "companyID":
        if (
          !validator.isAlphanumeric(value, "es-ES", {
            ignore: " ,.-0123456789&#",
          }) ||
          !validator.isLength(value, {
            min: 1,
          }) ||
          !validator.isNumeric(value)
        ) {
          error = {
            hasError: true,
            message: `A valid Company ID is required.`,
          };
        }
        break;
      default:
        break;
    }
  }
  setFunction((prevVal) => {
    const newDocs = [...prevVal];
    newDocs[idx][field] = error;
    return newDocs;
  });
};

export const validateFieldsCompanies = (
  companies,
  companiesError,
  setFunction,
  hasCompanyAddress
) => {
  let hasError = false;
  companies.forEach((clientCompany, idx) => {
    for (const field in clientCompany) {
      if (clientCompany[field] === "" || clientCompany[field] === null) {
        hasError = true;
        setFunction((prevVal) => {
          const newDocs = [...prevVal];
          newDocs[idx][field] = {
            hasError: true,
            message: "This field is requiered.",
          };
          return newDocs;
        });
      }
    }

    for (const field in companiesError[idx]) {
      if (companiesError[idx][field].hasError) hasError = true;
    }

    if (hasCompanyAddress[idx]) {
      validateFieldsAddress(
        companies[idx].address,
        companiesError[idx].address,
        setFunction,
        idx
      );
    }
  });

  return hasError;
};
