import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  nested: {
    paddingLeft: "32px",
  },
}));

const NavCollapse = (props) => {
  const { name, views, activeRoute, setMobileOpen } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prevVal) => !prevVal);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <props.icon />
        </ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {views.map((route, index) => {
            return (
              route.layout === "/admin" && (
                <NavLink
                  key={index}
                  to={`/admin${route.path}`}
                  className={classes.navLink}
                  onClick={() => {
                    setMobileOpen(false);
                  }}
                >
                  <ListItem
                    className={classes.nested}
                    selected={activeRoute(route.path)}
                    button
                  >
                    <ListItemIcon>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.name} />
                  </ListItem>
                </NavLink>
              )
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
