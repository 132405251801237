import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../components/SEO";

import {
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import Alert from "@mui/material/Alert";

import { useAuth } from "../../contexts/AuthContext";

import logo from "../../images/logo.png";

import styles from "./ForgotPassword.module.scss";

import Card from "components/card/Card";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardBody from "components/card/cardBody/CardBody";

const ForgotPassword = () => {
  const blankAlert = {
    hasAlert: false,
    type: "",
    message: "",
  };
  const [{ hasAlert, type, message }, setAlert] = useState(blankAlert);
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();

  const [{ email }, setCredentials] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setCredentials((prevVal) => {
      return {
        ...prevVal,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") return;
    try {
      setAlert(blankAlert);
      setLoading(true);
      await resetPassword(email);
      setAlert({
        hasAlert: true,
        type: "success",
        message: "An email has been sent to reset your password.",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.code === "auth/user-not-found") {
        setAlert({
          hasAlert: true,
          type: "error",
          message: "No user was found with that email.",
        });
      } else {
        setAlert({
          hasAlert: true,
          type: "error",
          message: "An error ocurred. Please try again.",
        });
      }
    }
  };

  return (
    <>
      <SEO title="Forgot my password" />
      <Card className={styles.loginCard}>
        <CardHeader signUp>
          <Typography
            variant="body2"
            style={{
              fontSize: "1.2rem",
              letterSpacing: "2px",
              textTransform: "uppercase",
            }}
          >
            Reset Password
          </Typography>
        </CardHeader>
        <CardBody
          style={{
            paddingLeft: "35px",
            paddingRight: "35px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" rowSpacing={3}>
              <Grid
                item
                xs={12}
                style={{
                  padding: "20px 0",
                  textAlign: "center",
                }}
              >
                <img className={styles.logo} src={logo} alt="logo ays" />
              </Grid>
              {hasAlert && (
                <Grid item xs={12} style={{ padding: "0", textAlign: "left" }}>
                  <Alert severity={type}>{message}</Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="outlined"
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
        <div style={{ margin: "1rem 0", marginTop: "0" }}>
          <Link
            to="/auth/login"
            style={{ color: "#189aa7", textDecoration: "none" }}
          >
            Sign In
          </Link>
        </div>
      </Card>
    </>
  );
};

export default ForgotPassword;
