import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SEO from "../../components/SEO";

import Card from "../../components/card/Card";
import CardHeader from "../../components/card/cardHeader/CardHeader";
import {
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useAuth } from "../../contexts/AuthContext";

import logo from "../../images/logo.png";

import styles from "./Login.module.scss";
import CardBody from "../../components/card/cardBody/CardBody";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const history = useHistory();

  const [{ email, password }, setCredentials] = useState({
    email: "",
    password: "",
  });

  const togglePasswordView = () => {
    setShowPassword((prevVal) => !prevVal);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setCredentials((prevVal) => {
      return {
        ...prevVal,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(email.trim(), password);
      history.push("/admin");
    } catch (error) {
      setLoading(false);
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        setError("Incorrect credentials.");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <SEO title="Log In" />
      <Card className={styles.loginCard}>
        <CardHeader signUp>
          <Typography
            variant="body2"
            style={{
              fontSize: "1.2rem",
              letterSpacing: "2px",
              textTransform: "uppercase",
            }}
          >
            Sign In
          </Typography>
        </CardHeader>
        <CardBody
          style={{
            paddingLeft: "35px",
            paddingRight: "35px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" rowSpacing={3}>
              <Grid
                item
                xs={12}
                style={{
                  padding: "20px 0",
                  textAlign: "center",
                }}
              >
                <img className={styles.logo} src={logo} alt="logo ays" />
              </Grid>
              {error && (
                <Grid item xs={12} style={{ padding: "0", textAlign: "left" }}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={password}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: password !== "" && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordView}
                          size="large"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button disabled={loading} color="primary" type="submit">
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
        {!loading && (
          <div style={{ margin: "1rem 0", marginTop: "0" }}>
            <Link
              to="/auth/forgot-password"
              style={{ color: "#189aa7", textDecoration: "none" }}
            >
              Forgot My Password
            </Link>
          </div>
        )}
      </Card>
    </>
  );
};

export default Login;
