import validator from "validator";

export const validateField = (setFunction, field, value, idx) => {
  let error = {
    hasError: false,
    message: "",
  };
  if (value === "" || null) {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "airline":
        if (
          !validator.isAlphanumeric(value, "es-ES", {
            ignore: " ,.-0123456789&#",
          }) ||
          !validator.isLength(value, {
            min: 1,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid Airline is required.`,
          };
        }
        break;
      case "code":
        if (
          !validator.isAlphanumeric(value, "es-ES", {
            ignore: " ,.-0123456789&#",
          }) &&
          !validator.isNumeric(value)
        ) {
          error = {
            hasError: true,
            message: `A valid code is required.`,
          };
        }
        break;
      default:
        break;
    }
  }
  setFunction((prevVal) => {
    const newDocs = [...prevVal];
    newDocs[idx][field] = error;
    return newDocs;
  });
};

export const validateFieldsFrequentFlyer = (
  frequentFlyer,
  frequentFlyerError,
  setFunction
) => {
  let hasError = false;
  frequentFlyer.forEach((clientFrequentFlyer, idx) => {
    for (const field in clientFrequentFlyer) {
      if (
        clientFrequentFlyer[field] === "" ||
        clientFrequentFlyer[field] === null
      ) {
        hasError = true;
        setFunction((prevVal) => {
          const newDocs = [...prevVal];
          newDocs[idx][field] = {
            hasError: true,
            message: "This field is requiered.",
          };
          return newDocs;
        });
      }
    }

    for (const field in frequentFlyerError[idx]) {
      if (frequentFlyerError[idx][field].hasError) hasError = true;
    }
  });

  return hasError;
};
