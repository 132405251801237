import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  Grid,
} from "@mui/material";

import styles from "./ClientModal.module.scss";
import Detail from "./Detail";

import { format } from "date-fns";
import { Language } from "@mui/icons-material";
import { useAuth } from "contexts/AuthContext";

const ClientModal = ({ open, setOpen, client }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userId = currentUser.id ? currentUser.id : currentUser.uid;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={styles.title} id="scroll-dialog-title">
        CLIENT DETAILS
      </DialogTitle>
      <DialogContent dividers={true} className={styles.dialog}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={styles.detailsCard} elevation={3}>
              <center className={styles.cardTitle}>PERSONAL DETAILS</center>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Detail label="Surname" value={client.surname} />
                </Grid>
                <Grid item xs={4}>
                  <Detail label="Given Name" value={client.givenName} />
                </Grid>
                <Grid item xs={4}>
                  <Detail
                    label="Birthdate"
                    value={format(new Date(client.birthdate), "MM/dd/yyyy")}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={styles.detailsCard} elevation={3}>
              <center className={styles.cardTitle}>DOCUMENTS</center>
              {client.documents.map((clientDoc, idx) => {
                const { docType, docNum, docCountry, issueDate, expDate } =
                  clientDoc;
                return (
                  <Paper
                    key={idx}
                    style={{ marginTop: idx > 0 && "1rem" }}
                    elevation={3}
                    className={styles.detailsCard}
                  >
                    <Grid container columnSpacing={2}>
                      <Grid item xs={6} md={2}>
                        <Detail label="Doc Type" value={docType} />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Detail label="Doc N°" value={docNum} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Detail label="Country" value={docCountry} />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Detail
                          label="Issue Date"
                          value={
                            issueDate
                              ? format(new Date(issueDate), "MM/dd/yyyy")
                              : "No Date"
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Detail
                          label="Exp Date"
                          value={
                            expDate
                              ? format(new Date(expDate), "MM/dd/yyyy")
                              : "No Date"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={styles.detailsCard} elevation={3}>
              <center className={styles.cardTitle}>CONTACT - PHONE</center>
              {client.phones.map((clientPhone, idx) => {
                const { phoneType, phoneCountry, phone } = clientPhone;
                return (
                  <Paper
                    key={idx}
                    style={{ marginTop: idx > 0 && "1rem" }}
                    elevation={3}
                    className={styles.detailsCard}
                  >
                    <Grid container columnSpacing={2}>
                      <Grid item xs={4}>
                        <Detail label="Phone Type" value={phoneType} />
                      </Grid>
                      <Grid item xs={2}>
                        <Detail
                          label={<Language fontSize="small" />}
                          value={phoneCountry}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Detail label="Phone N°" value={phone} />
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={styles.detailsCard} elevation={3}>
              <center className={styles.cardTitle}>CONTACT - EMAIL</center>
              {client.emails
                ? client.emails.map((clientEmail, idx) => {
                    const { emailType, email } = clientEmail;
                    return (
                      <Paper
                        key={idx}
                        style={{ marginTop: idx > 0 && "1rem" }}
                        elevation={3}
                        className={styles.detailsCard}
                      >
                        <Grid container columnSpacing={2}>
                          <Grid item xs={4}>
                            <Detail label="Email Type" value={emailType} />
                          </Grid>
                          <Grid item xs={8}>
                            <Detail label="Email" value={email} />
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })
                : "No Emails"}
            </Paper>
          </Grid>
          {client.address && (
            <Grid item xs={12}>
              <Paper className={styles.detailsCard} elevation={3}>
                <center className={styles.cardTitle}>ADDRESS</center>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Detail label="Street" value={client.address.street} />
                  </Grid>
                  <Grid item xs={6}>
                    <Detail
                      label="Apt, suite, etc."
                      value={
                        client.address.apt !== "" ? client.address.apt : "-"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Detail label="Country" value={client.address.country} />
                  </Grid>
                  <Grid item xs={4}>
                    <Detail
                      label="State/Province"
                      value={client.address.state}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Detail label="City" value={client.address.city} />
                  </Grid>
                  {client.address.district && (
                    <Grid item xs={6}>
                      <Detail
                        label="District"
                        value={client.address.district}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Detail
                      label="Zip/Postal Code"
                      value={client.address.zipCode}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          {client.companies && (
            <Grid item xs={12}>
              <Paper className={styles.detailsCard} elevation={3}>
                <center className={styles.cardTitle}>COMPANIES</center>
                {client.companies.map((clientCompany, idx) => {
                  const { company, companyId, address = null } = clientCompany;
                  return (
                    <Paper
                      key={idx}
                      style={{ marginTop: idx > 0 && "1rem" }}
                      elevation={3}
                      className={styles.detailsCard}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Detail label="Company" value={company} />
                        </Grid>
                        <Grid item xs={6}>
                          <Detail label="Company ID" value={companyId} />
                        </Grid>
                        {address && (
                          <>
                            <Grid item xs={6}>
                              <Detail label="Street" value={address.street} />
                            </Grid>
                            <Grid item xs={6}>
                              <Detail
                                label="Apt, suite, etc."
                                value={address.apt !== "" ? address.apt : "-"}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Detail label="Country" value={address.country} />
                            </Grid>
                            <Grid item xs={4}>
                              <Detail
                                label="State/Province"
                                value={address.state}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Detail label="City" value={address.city} />
                            </Grid>
                            {address.district && (
                              <Grid item xs={6}>
                                <Detail
                                  label="District"
                                  value={address.district}
                                />
                              </Grid>
                            )}
                            <Grid item xs={6}>
                              <Detail
                                label="Zip/Postal Code"
                                value={address.zipCode}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  );
                })}
              </Paper>
            </Grid>
          )}
          {client.frequentFlyer && (
            <Grid item xs={12}>
              <Paper className={styles.detailsCard} elevation={3}>
                <center className={styles.cardTitle}>FREQUENT FLYER</center>
                {client.frequentFlyer.map((clientFrequentFlyer, idx) => {
                  const { airline, code } = clientFrequentFlyer;
                  return (
                    <Paper
                      key={idx}
                      style={{ marginTop: idx > 0 && "1rem" }}
                      elevation={3}
                      className={styles.detailsCard}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Detail label="Airline" value={airline} />
                        </Grid>
                        <Grid item xs={6}>
                          <Detail label="Code" value={code} />
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })}
              </Paper>
            </Grid>
          )}
          {client.notes ? (
            client.notes.isPrivate ? (
              client.adminId === userId && (
                <Grid item xs={12}>
                  <Paper className={styles.detailsCard} elevation={3}>
                    <center className={styles.cardTitle}>Notes</center>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Detail label="" value={client.notes.notes} />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )
            ) : (
              <Grid item xs={12}>
                <Paper className={styles.detailsCard} elevation={3}>
                  <center className={styles.cardTitle}>Notes</center>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Detail label="" value={client.notes.notes} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientModal;
