import React from "react";
import PropTypes from "prop-types";

import styles from "./CardHeader.module.scss";

const CardHeader = ({ children, className, icon, signUp, stats, ...rest }) => {
  return (
    <div
      className={`${styles.cardHeader} ${icon && styles.cardHeaderIcon} ${
        signUp && styles.cardHeaderSignUp
      } ${stats && styles.cardHeaderStats} ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

CardHeader.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.bool,
  signUp: PropTypes.bool,
};

export default CardHeader;
