import validator from "validator";

export const validateField = (setFunction, field, value, idx) => {
  let error = {
    hasError: false,
    message: "",
  };

  if ((value === "" || null) && field !== "apt" && field !== "district") {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "street":
        const fieldName = field === "street" ? "Street" : "Apt,suite,etc.";
        if (
          !validator.isAlphanumeric(value, "es-ES", {
            ignore: " ,.-0123456789&#",
          }) ||
          !validator.isLength(value, {
            min: 1,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid ${fieldName} is required.`,
          };
        }
        break;
      case "zipCode":
        if (
          !validator.isPostalCode(value, "any") ||
          !validator.isLength(value, {
            min: 1,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid Zip/Postal code is required.`,
          };
        }
        break;
      default:
        break;
    }
  }
  if (idx > -1) {
    setFunction((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx]["address"][field] = error;
      return newDocs;
    });
  } else {
    setFunction((prevVal) => {
      return {
        ...prevVal,
        [field]: error,
      };
    });
  }
};

export const validateFieldsAddress = (
  address,
  addressError,
  setFunction,
  idx
) => {
  let hasError = false;
  for (const field in address) {
    if (
      (address[field] === "" || address[field] === null) &&
      field !== "apt" &&
      field !== "district"
    ) {
      hasError = true;
      if (idx > -1) {
        setFunction((prevVal) => {
          const newDocs = [...prevVal];
          newDocs[idx]["address"][field] = {
            hasError: true,
            message: "This field is required.",
          };
          return newDocs;
        });
      } else {
        setFunction((prevVal) => {
          return {
            ...prevVal,
            [field]: {
              hasError: true,
              message: "This field is required.",
            },
          };
        });
      }
    }
  }

  for (const field in addressError) {
    if (addressError[field].hasError) hasError = true;
  }

  return hasError;
};
