import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  Button,
  IconButton,
  FormHelperText,
} from "@mui/material";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Close, Add, Business } from "@mui/icons-material";

import styles from "./CompanyInput.module.scss";
import CardCheckBox from "components/card/cardCheckBox/CardCheckBox";

import { Country, State, City } from "country-state-city";
import useUpdateEffect from "hooks/useUpdateEffect";
import { validateField } from "utils/validations/client/companyValidition";
import { validateField as validateFieldAddress } from "utils/validations/client/addressValidation";

const CompanyAddressInput = ({
  idx,
  address: { street, apt, country, state, city, district, zipCode },
  handleChange,
  addressError: {
    street: streetError,
    apt: aptError,
    country: countryError,
    state: stateError,
    city: cityError,
    district: districtError,
    zipCode: zipCodeError,
  },
  handleBlur,
  isEdit,
}) => {
  const [location, setLocation] = useState({ country: "", state: "" });

  const getCountryCode = (countryName) => {
    const countryIdx = Country.getAllCountries().findIndex(
      (x) => x.name.toUpperCase() === countryName
    );
    return Country.getAllCountries()[countryIdx].isoCode;
  };

  const getStateCode = (stateName) => {
    const stateIdx = State.getStatesOfCountry(location.country).findIndex(
      (x) => x.name.toUpperCase() === stateName
    );
    return State.getStatesOfCountry(location.country)[stateIdx].isoCode;
  };

  useUpdateEffect(() => {
    if (country !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, country: getCountryCode(country) };
      });
    } else {
      setLocation((prevVal) => {
        return { ...prevVal, country: "" };
      });
    }
  }, [country]);

  useUpdateEffect(() => {
    if (state !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, state: getStateCode(state) };
      });
    } else {
      setLocation((prevVal) => {
        return { ...prevVal, state: "" };
      });
    }
  }, [state]);

  useEffect(() => {
    if (isEdit && country !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, country: getCountryCode(country) };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit && location.country !== "" && state !== "") {
      setLocation((prevVal) => {
        return { ...prevVal, state: getStateCode(state) };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.country]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          autoComplete="off"
          variant="standard"
          fullWidth
          label="Street"
          id={`street-${idx}`}
          name="street"
          onChange={(e) => {
            handleChange(e, idx);
          }}
          value={street}
          error={streetError.hasError}
          helperText={streetError.message}
          onBlur={(e) => {
            handleBlur(e, idx);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="off"
          variant="standard"
          fullWidth
          label="Apt, suite, etc."
          id={`apt-${idx}`}
          name="apt"
          onChange={(e) => {
            handleChange(e, idx);
          }}
          value={apt}
          error={aptError.hasError}
          helperText={aptError.message}
          onBlur={(e) => {
            handleBlur(e, idx);
          }}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <FormControl
          error={countryError.hasError}
          required
          variant="standard"
          fullWidth
        >
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            id={`country-${idx}`}
            name="country"
            value={country}
            onChange={(e) => {
              handleChange(e, idx);
            }}
            onBlur={(e) => {
              handleBlur(e, idx);
            }}
          >
            {Country.getAllCountries().map((country, idx) => {
              return (
                <MenuItem key={idx} value={country.name.toUpperCase()}>
                  {country.name.toUpperCase()}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{countryError.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4}>
        <FormControl
          error={stateError.hasError}
          required
          variant="standard"
          fullWidth
        >
          <InputLabel id="state-label">State/Province</InputLabel>
          <Select
            labelId="state-label"
            id={`state-${idx}`}
            name="state"
            value={state}
            onChange={(e) => {
              handleChange(e, idx);
            }}
            onBlur={(e) => {
              handleBlur(e, idx);
            }}
          >
            {State.getStatesOfCountry(location.country).map((state, idx) => {
              return (
                <MenuItem key={idx} value={state.name.toUpperCase()}>
                  {state.name.toUpperCase()}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{stateError.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4}>
        <FormControl
          error={cityError.hasError}
          required
          variant="standard"
          fullWidth
        >
          <InputLabel id="city-label">City</InputLabel>
          <Select
            labelId="city-label"
            id={`city-${idx}`}
            name="city"
            value={city}
            onChange={(e) => {
              handleChange(e, idx);
            }}
            onBlur={(e) => {
              handleBlur(e, idx);
            }}
          >
            {City.getCitiesOfState(location.country, location.state).map(
              (city, idx) => {
                return (
                  <MenuItem key={idx} value={city.name.toUpperCase()}>
                    {city.name.toUpperCase()}
                  </MenuItem>
                );
              }
            )}
          </Select>
          <FormHelperText>{cityError.message}</FormHelperText>
        </FormControl>
      </Grid>
      {country === "PERU" && (
        <Grid item xs={12} sm={6}>
          <TextField
            required
            autoComplete="off"
            variant="standard"
            fullWidth
            label="District"
            id={`district-${idx}`}
            name="district"
            onChange={(e) => {
              handleChange(e, idx);
            }}
            value={district}
            error={districtError.hasError}
            helperText={districtError.message}
            onBlur={(e) => {
              handleBlur(e, idx);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={country === "PERU" ? 6 : 4}>
        <TextField
          required
          autoComplete="off"
          variant="standard"
          fullWidth
          label="Zip/Postal Code"
          id={`zipCode-${idx}`}
          name="zipCode"
          onChange={(e) => {
            handleChange(e, idx);
          }}
          onBlur={(e) => {
            handleBlur(e, idx);
          }}
          value={zipCode}
          error={zipCodeError.hasError}
          helperText={zipCodeError.message}
        />
      </Grid>
    </Grid>
  );
};

const CompanyInput = ({
  companies,
  setCompanies,
  hasCompany,
  setHasCompany,
  hasCompanyAddress,
  setHasCompanyAddress,
  companiesError,
  setCompaniesError,
  isEdit,
}) => {
  const blankCompany = { company: "", companyId: "" };
  const blankError = { hasError: false, message: "" };
  const blankCompanyError = { company: blankError, companyId: blankError };

  const handleChange = (e, idx) => {
    const { value, name } = e.target;

    setCompanies((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][name] = value.toUpperCase();
      return newDocs;
    });
  };

  const handleBlur = (e, idx) => {
    const { value, name } = e.target;
    validateField(setCompaniesError, name, value, idx);
  };

  const handleBlurAddress = (e, idx) => {
    const { value, name } = e.target;
    validateFieldAddress(setCompaniesError, name, value, idx);
  };

  const handleAddressChange = (e, idx) => {
    const { value, name } = e.target;

    if (name === "country") {
      setCompanies((prevVal) => {
        const newArr = [...prevVal];
        newArr[idx].address.state = "";
        newArr[idx].address.city = "";
        return newArr;
      });
    }
    if (name === "state") {
      setCompanies((prevVal) => {
        const newArr = [...prevVal];
        newArr[idx].address.city = "";
        return newArr;
      });
    }

    setCompanies((prevVal) => {
      const newArr = [...prevVal];
      newArr[idx].address[name] = value.toUpperCase();
      return newArr;
    });
  };

  const handleCheck = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setCompanies([blankCompany]);
      setCompaniesError([blankCompanyError]);
    }

    setHasCompany(checked);
  };

  const handleAddressCheck = (e, idx) => {
    const { checked } = e.target;

    if (checked) {
      setCompanies((prevVal) => {
        const newArr = [...prevVal];
        newArr[idx].address = {
          street: "",
          apt: "",
          country: "",
          state: "",
          city: "",
          district: "",
          zipCode: "",
        };
        return newArr;
      });
      setCompaniesError((prevVal) => {
        const newArr = [...prevVal];
        newArr[idx].address = {
          street: blankError,
          apt: blankError,
          country: blankError,
          state: blankError,
          city: blankError,
          district: blankError,
          zipCode: blankError,
        };
        return newArr;
      });
    } else {
      setCompanies((prevVal) => {
        console.log("Here");
        const newArr = [...prevVal];
        delete newArr[idx].address;
        return newArr;
      });
      setCompaniesError((prevVal) => {
        const newArr = [...prevVal];
        delete newArr[idx].address;
        return newArr;
      });
    }

    setHasCompanyAddress((prevVal) => {
      const newArr = [...prevVal];
      newArr[idx] = checked;
      return newArr;
    });
  };

  const addCompany = () => {
    setCompanies((prevVal) => {
      return [...prevVal, blankCompany];
    });
    setCompaniesError((prevVal) => {
      return [...prevVal, blankCompanyError];
    });

    setHasCompanyAddress((prevVal) => {
      return [...prevVal, false];
    });
  };

  const deleteCompany = (idx) => {
    setCompanies((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setCompaniesError((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setHasCompanyAddress((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <Business />
        </CardIcon>
        <CardCheckBox checked={hasCompany} handleChange={handleCheck} />
        <h4 className={styles.cardTitle}>Company</h4>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        <Collapse in={hasCompany}>
          {companies.map((companyDetail, idx) => {
            const { company, companyId, address } = companyDetail;
            const { company: companyError, companyId: companyIdError } =
              companiesError[idx];
            return (
              <Card
                key={idx}
                style={{
                  margin: "0px",
                  textAlign: "left",
                  marginTop: idx > 0 ? "1rem" : "0px",
                }}
              >
                {idx > 0 && (
                  <IconButton
                    color="error"
                    size="small"
                    className={styles.closeBtn}
                    onClick={() => {
                      deleteCompany(idx);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required={hasCompany}
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        label="Company"
                        id={`company-${idx}`}
                        name="company"
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        error={companyError.hasError}
                        helperText={companyError.message}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                        value={company}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required={hasCompany}
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        label="Company ID"
                        id={`companyId-${idx}`}
                        name="companyId"
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        error={companyIdError.hasError}
                        helperText={companyIdError.message}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                        value={companyId}
                      />
                    </Grid>
                  </Grid>
                  <FormGroup className={styles.checkBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasCompanyAddress[idx]}
                          onChange={(e) => {
                            handleAddressCheck(e, idx);
                          }}
                        />
                      }
                      label={<span style={{ fontWeight: 300 }}>Address</span>}
                    />
                  </FormGroup>
                  {address && (
                    <CompanyAddressInput
                      address={address}
                      handleChange={handleAddressChange}
                      handleBlur={handleBlurAddress}
                      addressError={companiesError[idx].address}
                      idx={idx}
                      isEdit={isEdit}
                    />
                  )}
                </CardBody>
              </Card>
            );
          })}
          <Button
            color="success"
            variant="contained"
            className={styles.addBtn}
            onClick={addCompany}
          >
            <Add />
          </Button>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default CompanyInput;
