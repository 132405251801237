import React from "react";

const Detail = ({ label, value }) => {
  return (
    <div>
      <p>{label}</p>
      <p style={{ fontWeight: 300, whiteSpace: "pre-line" }}>{value}</p>
    </div>
  );
};

export default Detail;
