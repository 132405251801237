import React from "react";

import clsx from "clsx";
import GlobalFilter from "./GlobalFilter";
import { lighten } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { Toolbar, Typography, IconButton } from "@mui/material";
import { Refresh, Dvr, Security } from "@mui/icons-material";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    handleRefresh,
    selectedRow,
    handleOpenDialog,
    // handleDelete,
    disableRefresh,
    isUsersTable,
    handleRoleChange,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedRow !== null,
      })}
    >
      {selectedRow !== null ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        ></Typography>
      ) : (
        <>
          {handleRefresh && (
            <IconButton onClick={handleRefresh} disabled={disableRefresh} size="large">
              <Refresh />
            </IconButton>
          )}
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
          ></Typography>
        </>
      )}

      {selectedRow !== null ? (
        <>
          {!isUsersTable && (
            <>
              <IconButton
                aria-label="view"
                onClick={() => {
                  handleOpenDialog(selectedRow);
                }}
                size="large">
                <Dvr color="primary" />
              </IconButton>

              {/* <IconButton
                aria-label="Edit"
                onClick={() => {
                  handleOpenDialog(selectedRow, "edit");
                }}
              >
                <Edit
                  style={{
                    color: "orange",
                  }}
                />
              </IconButton> */}
            </>
          )}
          {isUsersTable && (
            <IconButton
              aria-label="changeRole"
              onClick={() => {
                handleRoleChange(selectedRow);
              }}
              size="large">
              <Security
                style={{
                  color: "orange",
                }}
              />
            </IconButton>
          )}
          {/* <IconButton
            aria-label="delete"
            onClick={() => {
              handleDelete(selectedRow);
            }}
          >
            <Delete color="error" />
          </IconButton> */}
        </>
      ) : (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  setGlobalFilter: PropTypes.func,
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
};

export default TableToolbar;
