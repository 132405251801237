import validator from "validator";

export const validateField = (setFunction, field, value, idx) => {
  let error = {
    hasError: false,
    message: "",
  };
  if (value === "" || null) {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "email":
        if (!validator.isEmail(value)) {
          error = {
            hasError: true,
            message: `A valid email is required.`,
          };
        }
        break;

      default:
        break;
    }
  }
  setFunction((prevVal) => {
    const newDocs = [...prevVal];
    newDocs[idx][field] = error;
    return newDocs;
  });
};

export const validateFieldsEmails = (emails, emailsError, setFunction) => {
  let hasError = false;
  emails.forEach((clientEmail, idx) => {
    for (const field in clientEmail) {
      if (clientEmail[field] === "" || clientEmail[field] === null) {
        hasError = true;
        setFunction((prevVal) => {
          const newDocs = [...prevVal];
          newDocs[idx][field] = {
            hasError: true,
            message: "This field is requiered.",
          };
          return newDocs;
        });
      }
    }

    for (const field in emailsError[idx]) {
      if (emailsError[idx][field].hasError) hasError = true;
    }
  });

  return hasError;
};
