import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = ({ title, description }) => {
  const seo = {
    title: title || "Admin",
    description:
      description ||
      "GlaMar Travel Admin System",
  };
  return (
    <Helmet title={seo.title} titleTemplate="%s | GlaMar Travel Admin">
      <meta name="description" content={seo.description} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;
