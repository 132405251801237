import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import { Add, Close, ContactMail } from "@mui/icons-material";

import styles from "./EmailInput.module.scss";
import CardCheckBox from "components/card/cardCheckBox/CardCheckBox";
import { validateField } from "utils/validations/client/emailsValidation";

const EmailInput = ({
  emails,
  setEmails,
  hasEmail,
  setHasEmail,
  emailsError,
  setEmailsError,
}) => {
  const blankEmail = { emailType: "", email: "" };

  const blankError = { hasError: false, message: "" };

  const blankEmailError = {
    emailType: blankError,
    email: blankError,
  };

  const handleChange = (e, idx) => {
    const { value, name } = e.target;

    setEmails((prevVal) => {
      const newDocs = [...prevVal];
      newDocs[idx][name] = value;
      return newDocs;
    });
  };

  const handleBlur = (e, idx) => {
    const { value, name } = e.target;
    validateField(setEmailsError, name, value, idx);
  };

  const addEmail = () => {
    setEmails((prevVal) => {
      return [...prevVal, blankEmail];
    });
    setEmailsError((prevVal) => {
      return [...prevVal, blankEmailError];
    });
  };

  const deleteEmail = (idx) => {
    setEmails((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
    setEmailsError((prevVal) => {
      return prevVal.filter((x, index) => index !== idx);
    });
  };

  const handleCheck = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setEmails([blankEmail]);
      setEmailsError([blankEmailError]);
    }

    setHasEmail(checked);
  };

  return (
    <Card style={{ marginBottom: "0px" }}>
      <CardHeader icon>
        <CardIcon>
          <ContactMail />
        </CardIcon>
        <CardCheckBox checked={hasEmail} handleChange={handleCheck} />
        <h4 className={styles.cardTitle}>Contact - Email</h4>
      </CardHeader>
      <CardBody style={{ textAlign: "center" }}>
        {emails.map((emailDetail, idx) => {
          const { emailType, email } = emailDetail;
          const { emailType: emailTypeError, email: emailError } =
            emailsError[idx];
          return (
            <Card
              key={idx}
              style={{
                margin: "0px",
                textAlign: "left",
                marginTop: idx > 0 ? "1rem" : "0px",
              }}
            >
              {idx > 0 && (
                <IconButton
                  color="error"
                  size="small"
                  className={styles.closeBtn}
                  onClick={() => {
                    deleteEmail(idx);
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <FormControl
                      error={emailTypeError.hasError}
                      required={hasEmail}
                      disabled={!hasEmail}
                      variant="standard"
                      fullWidth
                    >
                      <InputLabel id={`emailType-label-${idx}`}>
                        Type
                      </InputLabel>
                      <Select
                        labelId={`emailType-label-${idx}`}
                        id={`emailType-${idx}`}
                        name="emailType"
                        value={emailType}
                        onChange={(e) => {
                          handleChange(e, idx);
                        }}
                        onBlur={(e) => {
                          handleBlur(e, idx);
                        }}
                      >
                        <MenuItem value="PERSONAL">PERSONAL</MenuItem>
                        <MenuItem value="WORK">WORK</MenuItem>
                        <MenuItem value="OTHER">OTHER</MenuItem>
                      </Select>
                      <FormHelperText>{emailTypeError.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required={hasEmail}
                      autoComplete="off"
                      disabled={!hasEmail}
                      variant="standard"
                      fullWidth
                      label="Email"
                      id={`email-${idx}`}
                      name="email"
                      value={email}
                      error={emailError.hasError}
                      helperText={emailError.message}
                      onChange={(e) => {
                        handleChange(e, idx);
                      }}
                      onBlur={(e) => {
                        handleBlur(e, idx);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          );
        })}

        <Button
          color="success"
          variant="contained"
          className={styles.addBtn}
          onClick={addEmail}
          disabled={!hasEmail}
        >
          <Add />
        </Button>
      </CardBody>
    </Card>
  );
};

export default EmailInput;
