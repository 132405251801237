import React from "react";

import styles from "./Card.module.scss";

const Card = ({ children, className, chart, ...rest }) => {
  return (
    <div
      className={`${styles.card} ${className} ${chart && styles.chart}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
