import { validateFieldsAddress } from "./addressValidation";
import { validateFieldsCompanies } from "./companyValidition";
import { validateFieldsDocuments } from "./documentsValidation";
import { validateFieldsEmails } from "./emailsValidation";
import { validateFieldsFrequentFlyer } from "./frequentFlyerValidation";
import { validateFieldsPersonalDetails } from "./personalDetailValidation";
import { validateFieldsPhones } from "./phonesValidation";

export const validateErrors = (
  clientInfo,
  clientError,
  setClientError,
  clientFields
) => {
  const {
    personalDetails,
    documents,
    phones,
    emails,
    address,
    companies,
    frequentFlyer,
  } = clientInfo;
  const {
    personalDetailsError,
    documentsError,
    phonesError,
    emailsError,
    addressError,
    companiesError,
    frequentFlyerError,
  } = clientError;
  const {
    setPersonalDetailsError,
    setDocumentsError,
    setPhonesError,
    setEmailsError,
    setAddressError,
    setCompaniesError,
    setFrequentFlyerError,
  } = setClientError;
  const {
    hasEmail,
    hasAddress,
    hasCompany,
    hasFrequentFlyer,
    hasCompanyAddress,
  } = clientFields;

  let hasErrors = false;

  hasErrors = validateFieldsPersonalDetails(
    personalDetails,
    personalDetailsError,
    setPersonalDetailsError
  );

  if (hasErrors) return hasErrors;

  hasErrors = validateFieldsDocuments(
    documents,
    documentsError,
    setDocumentsError
  );

  if (hasErrors) return hasErrors;

  hasErrors = validateFieldsPhones(phones, phonesError, setPhonesError);

  if (hasErrors) return hasErrors;

  if (hasEmail) {
    hasErrors = validateFieldsEmails(emails, emailsError, setEmailsError);
    if (hasErrors) return hasErrors;
  }
  if (hasAddress) {
    hasErrors = validateFieldsAddress(address, addressError, setAddressError);
    if (hasErrors) return hasErrors;
  }

  if (hasCompany) {
    hasErrors = validateFieldsCompanies(
      companies,
      companiesError,
      setCompaniesError,
      hasCompanyAddress
    );
    if (hasErrors) return hasErrors;
  }
  if (hasFrequentFlyer) {
    hasErrors = validateFieldsFrequentFlyer(
      frequentFlyer,
      frequentFlyerError,
      setFrequentFlyerError
    );
    if (hasErrors) return hasErrors;
  }

  return hasErrors;
};
