import React, { createContext, useContext, useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { auth, EmailAuthProvider } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function updateEmail(email) {
    return auth.currentUser.updateEmail(email);
  }

  function updateDisplayName(displayName) {
    return auth.currentUser.updateProfile({ displayName });
  }

  function updatePassword(password) {
    return auth.currentUser.updatePassword(password);
  }

  function resetPassword(email) {
    auth.languageCode = "es";
    return auth.sendPasswordResetEmail(email);
  }

  function reAuthenticate(email, password) {
    const credential = EmailAuthProvider.credential(email, password);
    return new Promise((resolve, reject) => {
      auth.currentUser
        .reauthenticateWithCredential(credential)
        .then((userCred) => resolve(userCred))
        .catch((reason) => reject(reason));
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
    login,
    logout,
    updateEmail,
    updateDisplayName,
    updatePassword,
    reAuthenticate,
    resetPassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading ? (
        children
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LinearProgress color="primary" style={{ width: "85%" }} />
        </div>
      )}
    </AuthContext.Provider>
  );
}
