import { PeopleAlt, PersonAdd } from "@mui/icons-material";
// import Dashboard from "./views/dashboard/Dashboard";
import Login from "./views/login/Login";
import ForgotPassword from "./views/forgotPassword/ForgotPassword";
import RegisterClient from "views/registerClient/RegisterClient";
import ViewClients from "views/viewClients/ViewClients";

const paths = [
  {
    layout: "/auth",
    component: Login,
    path: "/login",
  },
  {
    layout: "/auth",
    component: ForgotPassword,
    path: "/forgot-password",
  },
  // {
  //     layout: "/admin",
  //     component: Dashboard,
  //     path: "/dashboard",
  //     icon: DashboardIcon,
  //     name: "Dashboard",
  // },
  {
    layout: "/admin",
    component: ViewClients,
    path: "/view-clients",
    icon: PeopleAlt,
    name: "View Clients",
  },
  {
    layout: "/admin",
    component: RegisterClient,
    path: "/register-client",
    icon: PersonAdd,
    name: "Register Client",
  },
];

export default paths;
