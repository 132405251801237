import validator from "validator";

export const validateField = (
  setFunction,
  field,
  value,
  idx,
  docType,
  issueDate
) => {
  let error = {
    hasError: false,
    message: "",
  };

  if ((value === "" || null) && field !== "issueDate" && field !== "expDate") {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "docNum":
        if (
          (!validator.isAlphanumeric(value) && !validator.isNumeric(value)) ||
          !validator.isLength(value, {
            min: 6,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid document number is required`,
          };
        }
        break;
      case "issueDate":
        if (new Date(value).getTime() > new Date().getTime()) {
          error = {
            hasError: true,
            message: `A valid document issue date is required`,
          };
        }
        break;
      case "expDate":
        if (
          issueDate !== "" &&
          issueDate !== null &&
          new Date(value).getTime() < new Date(issueDate).getTime()
        ) {
          error = {
            hasError: true,
            message: `A valid document expiration date is required`,
          };
        }
        break;
      default:
        break;
    }
  }
  setFunction((prevVal) => {
    const newDocs = [...prevVal];
    newDocs[idx][field] = error;
    return newDocs;
  });
};

export const validateFieldsDocuments = (
  documents,
  documentsError,
  setFunction
) => {
  let hasError = false;
  documents.forEach((clientDoc, idx) => {
    for (const field in clientDoc) {
      if (
        (clientDoc[field] === "" || clientDoc[field] === null) &&
        field !== "issueDate" &&
        field !== "expDate"
      ) {
        hasError = true;
        setFunction((prevVal) => {
          const newDocs = [...prevVal];
          newDocs[idx][field] = {
            hasError: true,
            message: "This field is requiered.",
          };
          return newDocs;
        });
      }
    }

    for (const field in documentsError[idx]) {
      if (documentsError[idx][field].hasError) hasError = true;
    }
  });

  return hasError;
};
