import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect, useMemo } from "react";
import Card from "../../components/card/Card";
import CardHeader from "../../components/card/cardHeader/CardHeader";
import CardIcon from "../../components/card/cardIcon/CardIcon";
import { Contacts, Lock, Edit, Close, Save } from "@mui/icons-material";

import { useAuth } from "../../contexts/AuthContext";

import SEO from "../../components/SEO";

import styles from "./Profile.module.scss";
import CardBody from "../../components/card/cardBody/CardBody";

import {
  validateField,
  validateFields,
} from "../../utils/validations/personalInfoValidation";
import { gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { successSwal, errorSwal, loadingSwal } from "../../utils/utils";

const UPDATE_ADMIN = gql`
  mutation UpdateAdmin(
    $id: String!
    $docType: String
    $docNum: String
    $givenName: String
    $surname: String
    $phone: String
    $email: String
  ) {
    updateAdmin(
      id: $id
      docType: $docType
      docNum: $docNum
      givenName: $givenName
      surname: $surname
      phone: $phone
      email: $email
    ) {
      docType
      docNum
      givenName
      surname
      phone
      email
    }
  }
`;

const useStyles = makeStyles({
  paper: {
    width: "320px",
  },
});

const ChangePasswordDialog = ({ open, handleDialogClose, handleUpdate }) => {
  const classes = useStyles();

  const [{ password, confirmPassword, currentPassword }, setPassword] =
    useState({
      password: "",
      confirmPassword: "",
      currentPassword: "",
    });

  const [{ passwordError, confirmPasswordError }, setPasswordError] = useState({
    passwordError: { hasError: false, message: "" },
    confirmPasswordError: { hasError: false, message: "" },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPassword((prevVal) => {
      return { ...prevVal, [name]: value };
    });
  };

  const handleClose = () => {
    handleDialogClose();
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const field =
      name === "password" ? "passwordError" : "confirmPasswordError";
    if (value.length < 6) {
      setPasswordError((prevVal) => {
        return {
          ...prevVal,
          [field]: {
            hasError: true,
            message: "Password requires minimum 6 characters",
          },
        };
      });
    } else {
      setPasswordError((prevVal) => {
        return {
          ...prevVal,
          [field]: { hasError: false, message: "" },
        };
      });
    }
  };

  const handleSubmit = () => {
    let hasError;

    if (password === "") {
      setPasswordError((prevVal) => {
        return {
          ...prevVal,
          passwordError: {
            hasError: true,
            message: "This field is required.",
          },
        };
      });

      hasError = true;
    }

    if (confirmPassword === "") {
      setPasswordError((prevVal) => {
        return {
          ...prevVal,
          confirmPasswordError: {
            hasError: true,
            message: "This field is required.",
          },
        };
      });
      hasError = true;
    }

    if (
      confirmPassword !== password &&
      !passwordError.hasError &&
      !confirmPasswordError.hasError
    ) {
      setPasswordError((prevVal) => {
        return {
          ...prevVal,
          confirmPasswordError: {
            hasError: true,
            message: "Passwords do not match.",
          },
          passwordError: {
            hasError: true,
            message: "Passwords do not match.",
          },
        };
      });
      hasError = true;
    }

    if (hasError || passwordError.hasError || confirmPasswordError.hasError)
      return;

    handleUpdate(password, currentPassword);
  };

  useEffect(() => {
    if (!open) {
      setPassword({
        password: "",
        confirmPassword: "",
        currentPassword: "",
      });

      setPasswordError({
        passwordError: { hasError: false, message: "" },
        confirmPasswordError: { hasError: false, message: "" },
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>CAMBIAR CONTRASEÑA</DialogTitle>
      <DialogContent style={{ padding: "2rem 3rem" }} dividers>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              required
              label="Current Password"
              id="currentPassword"
              name="currentPassword"
              type="password"
              value={currentPassword}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="New Password"
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              autoComplete="off"
              helperText={passwordError.message}
              error={passwordError.hasError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              autoComplete="off"
              helperText={confirmPasswordError.message}
              error={confirmPasswordError.hasError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Profile = () => {
  const reactSwal = useMemo(() => {
    return withReactContent(Swal);
  }, []);

  const {
    currentUser,
    setCurrentUser,
    updateEmail,
    updatePassword,
    reAuthenticate,
  } = useAuth();

  const [open, setOpen] = useState(false);

  const [emailEdit, setEmailEdit] = useState(false);

  const [email, setEmail] = useState("");

  const [{ emailError }, setEmailError] = useState({
    emailError: {
      hasError: false,
      message: "",
    },
  });

  const [personalInfo, setPersonalInfo] = useState({
    docType: "",
    docNum: "",
    givenName: "",
    surname: "",
    phone: "",
    address: "",
  });

  const { docType, docNum, givenName, surname, phone, address } = personalInfo;

  const [personalInfoError, setPersonalInfoError] = useState({
    docTypeError: { hasError: false, message: "" },
    docNumError: { hasError: false, message: "" },
    givenNameError: { hasError: false, message: "" },
    surnameError: { hasError: false, message: "" },
    phoneError: { hasError: false, message: "" },
    addressError: { hasError: false, message: "" },
  });

  const [edit, setEdit] = useState(false);

  const {
    docTypeError,
    docNumError,
    givenNameError,
    surnameError,
    phoneError,
    addressError,
  } = personalInfoError;

  const [updateAdmin] = useMutation(UPDATE_ADMIN, {
    onCompleted: ({ updateAdmin }) => {
      setCurrentUser((prevVal) => ({ ...prevVal, ...updateAdmin }));
      reactSwal.fire({
        ...successSwal,
        text: "Information updated correctly!",
      });
      setEdit(false);
      setEmailEdit(false);
    },
    onError: (error) => {
      console.log(error);
      let errorMessage = "An error ocurred. Please try again.";
      switch (error.message) {
        case "The email address is already in use by another account.":
          errorMessage = "The email is already registered.";
          break;
        case "User is already registered!":
          errorMessage = "The ID is already registered";
          break;
        default:
          break;
      }

      reactSwal.fire({ ...errorSwal, text: errorMessage });
    },
  });

  const reset = () => {
    setPersonalInfo({
      givenName: currentUser.givenName,
      surname: currentUser.surname,
      docNum: currentUser.docNum,
      docType: currentUser.docType,
      address: currentUser.address,
      phone: currentUser.phone,
    });

    setPersonalInfoError({
      docTypeError: { hasError: false, message: "" },
      docNumError: { hasError: false, message: "" },
      givenNameError: { hasError: false, message: "" },
      surnameError: { hasError: false, message: "" },
      phoneError: { hasError: false, message: "" },
      addressError: { hasError: false, message: "" },
    });
  };

  useEffect(() => {
    reset();
    setEmail(currentUser.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "docType" && docNum !== "") {
      validateField(setPersonalInfoError, "docNum", docNum, value);
    }

    if (name === "email") {
      setEmail(value);
    } else {
      setPersonalInfo((prevVal) => {
        return {
          ...prevVal,
          [name]: value.toUpperCase(),
        };
      });
    }
  };

  const handleBlur = (e) => {
    const { value, name } = e.target;

    if (name === "email") {
      validateField(setEmailError, "email", value.trim());
    } else if (name === "docNum") {
      validateField(setPersonalInfoError, name, value.trim(), docType);
    } else {
      validateField(setPersonalInfoError, name, value.trim());
    }
  };

  const handleCancel = () => {
    reset();
    setEdit(false);
  };

  const handleSave = () => {
    const hasError = validateFields(
      personalInfo,
      personalInfoError,
      setPersonalInfoError
    );
    if (!hasError) {
      reactSwal.fire({ ...loadingSwal, titleText: "Actualizando..." });
      updateAdmin({ variables: { id: currentUser.id, ...personalInfo } });
    }
  };

  const handleSaveEmail = async () => {
    try {
      if (
        email.toLowerCase() === currentUser.email.toLowerCase() ||
        emailError.hasError
      )
        return;

      reactSwal.fire({ ...loadingSwal, titleText: "Updating..." });
      await updateEmail(email.trim());
      updateAdmin({ variables: { id: currentUser.id, email } });
    } catch (error) {
      console.log(error.message);
      if (
        error.message ===
        "Firebase: This operation is sensitive and requires recent authentication. Log in again before retrying this request. (auth/requires-recent-login)."
      ) {
        reactSwal.fire({
          ...errorSwal,
          icon: "warning",
          text: "Log in again to change your email.",
        });
      } else {
        reactSwal.fire(errorSwal);
      }
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handlePasswordUpdate = async (password, currentPassword) => {
    try {
      reactSwal.fire({ ...loadingSwal, title: "Update password..." });
      await reAuthenticate(currentUser.email, currentPassword);
      await updatePassword(password);
      reactSwal.fire({
        ...successSwal,
        text: "Password updated correctly.",
      });
      setOpen(false);
    } catch (error) {
      await sleep(1);
      console.log(error);
      if (error.code === "auth/wrong-password") {
        reactSwal.fire({ ...errorSwal, text: "Incorrect current password" });
      }
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ChangePasswordDialog
        open={open}
        handleDialogClose={handleDialogClose}
        handleUpdate={handlePasswordUpdate}
      />
      <SEO title="Perfil" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader icon>
              <CardIcon>
                <Contacts />
              </CardIcon>
              <h4 className={styles.cardTitle}>Personal Information</h4>
            </CardHeader>
            <CardBody>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Given Name"
                    name="givenName"
                    id="givenName"
                    value={givenName}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={givenNameError.hasError}
                    helperText={givenNameError.message}
                    required={edit}
                    inputProps={{ readOnly: !edit }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Surname"
                    name="surname"
                    id="surname"
                    value={surname}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={surnameError.hasError}
                    helperText={surnameError.message}
                    required={edit}
                    inputProps={{ readOnly: !edit }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={docTypeError.hasError}
                    variant="standard"
                    required={edit}
                  >
                    <InputLabel id="docType-label">Document Type</InputLabel>
                    <Select
                      labelId="docType-label"
                      id="docType"
                      name="docType"
                      value={docType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ readOnly: !edit }}
                      variant="standard"
                    >
                      <MenuItem value="DNI">DNI</MenuItem>
                      <MenuItem value="PASAPORTE">PASAPORTE</MenuItem>
                      <MenuItem value="CE">CE</MenuItem>
                    </Select>
                    {docTypeError.hasError && (
                      <FormHelperText>{docTypeError.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Document N°"
                    name="docNum"
                    id="docNum"
                    value={docNum}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={docNumError.hasError}
                    helperText={docNumError.message}
                    required={edit}
                    inputProps={{
                      readOnly: !edit,
                      maxLength: docType === "DNI" ? 8 : 12,
                    }}
                    variant="standard"
                  />
                </Grid>
                {currentUser.displayName === "CLIENT" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      name="address"
                      id="address"
                      value={address}
                      onChange={handleChange}
                      fullWidth
                      onBlur={handleBlur}
                      error={addressError.hasError}
                      helperText={addressError.message}
                      required={edit}
                      inputProps={{ readOnly: !edit }}
                      variant="standard"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Phone N"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={phoneError.hasError}
                    helperText={phoneError.message}
                    required={edit}
                    inputProps={{ readOnly: !edit }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <div className={styles.actions}>
                {edit && <Button onClick={handleCancel}>Cancel</Button>}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (edit) {
                      handleSave();
                    } else {
                      setEdit((prevVal) => !prevVal);
                    }
                  }}
                >
                  {edit ? "Save" : "Update"}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader icon>
              <CardIcon>
                <Lock />
              </CardIcon>
              <h4 className={styles.cardTitle}>Credentials</h4>
            </CardHeader>
            <CardBody>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={emailError.hasError}
                    helperText={emailError.message}
                    required={emailEdit}
                    inputProps={{ readOnly: !emailEdit }}
                  />
                  {emailEdit && (
                    <>
                      <IconButton
                        onClick={() => {
                          setEmailEdit((prevVal) => !prevVal);
                          setEmailError({
                            emailError: {
                              hasError: false,
                              message: "",
                            },
                          });
                          setEmail(currentUser.email);
                        }}
                        size="small"
                        style={{ marginTop: "5px" }}
                      >
                        <Close color="error" />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={{ marginTop: "5px", marginLeft: "5px" }}
                        onClick={() => {
                          handleSaveEmail();
                        }}
                      >
                        <Save color="primary" />
                      </IconButton>
                    </>
                  )}
                  {!emailEdit && (
                    <IconButton
                      size="small"
                      style={{ marginTop: "5px" }}
                      onClick={() => {
                        setEmailEdit((prevVal) => !prevVal);
                      }}
                    >
                      <Edit style={{ color: "orange" }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "grid", placeItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
