import React, { useMemo, useState } from "react";

import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client";

import { People, Dvr, Delete, Edit } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import Card from "components/card/Card";
import CardBody from "components/card/cardBody/CardBody";
import CardHeader from "components/card/cardHeader/CardHeader";
import CardIcon from "components/card/cardIcon/CardIcon";
import ReactTable from "components/reactTable/ReactTable";
import ClientModal from "./components/ClientModal";
import SEO from "components/SEO";

import styles from "./ViewClients.module.scss";
import { useAuth } from "contexts/AuthContext";
import ClientEditModal from "./components/ClientEditModal";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { errorSwal, confirmationSwal } from "utils/utils";

const GET_CLIENTS = gql`
  query GetClients($adminId: String!) {
    clients(adminId: $adminId) {
      id
      surname
      givenName
      documents {
        docType
        docNum
      }
      adminId
      isGlobal
    }
  }
`;

const DELETE_CLIENT = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
      surname
      givenName
    }
  }
`;

const GET_CLIENT = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      surname
      givenName
      birthdate
      documents {
        docType
        docNum
        docCountry
        issueDate
        expDate
      }
      phones {
        phoneType
        phoneCountry
        phone
      }
      emails {
        emailType
        email
      }
      address {
        street
        apt
        country
        state
        city
        district
        zipCode
      }
      companies {
        company
        companyId
        address {
          street
          apt
          country
          state
          city
          district
          zipCode
        }
      }
      frequentFlyer {
        airline
        code
      }
      notes {
        isPrivate
        notes
      }
      adminId
      isGlobal
    }
  }
`;

const ViewClients = () => {
  const { currentUser } = useAuth();
  const reactSwal = useMemo(() => {
    return withReactContent(Swal);
  }, []);

  const [client, setClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dialog, setDialog] = useState("view");

  const { data, loading, error, refetch } = useQuery(GET_CLIENTS, {
    variables: { adminId: currentUser.id ? currentUser.id : currentUser.uid },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [getClient, { loading: loadingClient }] = useLazyQuery(GET_CLIENT, {
    onCompleted: ({ client }) => {
      setClient(client);
      if (dialog === "view") {
        setOpen(true);
      } else {
        setOpenEdit(true);
      }
    },
    onError: (error) => {
      console.log(error);
      reactSwal.fire(errorSwal);
    },
    fetchPolicy: "network-only",
  });

  const [deleteClient, { loading: loadingDelete }] = useMutation(
    DELETE_CLIENT,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
        reactSwal.fire(errorSwal);
      },
    }
  );

  const handleRefresh = () => {
    refetch();
  };

  const handleOpenDialog = (id) => {
    getClient({ variables: { id } });
  };

  const clientData = useMemo(() => {
    if (!data) return [];
    return data.clients.map((client) => {
      const { id, surname, givenName, documents } = client;
      return {
        id,
        surname,
        givenName,
        docType: documents[0].docType,
        docNum: documents[0].docNum,
        actions: (
          <div>
            <IconButton
              size="small"
              onClick={() => {
                setDialog("view");
                handleOpenDialog(id);
              }}
              color="secondary"
            >
              <Dvr fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setDialog("edit");
                handleOpenDialog(id);
              }}
              color="warning"
              style={{ marginLeft: "5px" }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={async () => {
                const res = await reactSwal.fire(confirmationSwal);
                if (res.isConfirmed) deleteClient({ variables: { id } });
              }}
              color="error"
              style={{ marginLeft: "5px" }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </div>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = useMemo(
    () => [
      {
        accessor: "actions", // accessor is the "key" in the data
        className: styles.actions,
      },
      {
        Header: "Surname",
        accessor: "surname", // accessor is the "key" in the data
      },
      {
        Header: "Given Name",
        accessor: "givenName", // accessor is the "key" in the data
      },
      {
        Header: "Document Type",
        accessor: "docType",
      },
      {
        Header: "Document N",
        accessor: "docNum",
      },
    ],
    []
  );

  return (
    <>
      <SEO title="View Clients" />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon>
                <People />
              </CardIcon>
              <h4 className={styles.cardTitle}>Clients</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={clientData}
                columns={columns}
                handleRefresh={handleRefresh}
                disableRefresh={false}
                error={error}
                loading={loading || loadingClient || loadingDelete}
                dialog={
                  client && (
                    <ClientModal
                      open={open}
                      setOpen={setOpen}
                      client={client}
                    />
                  )
                }
                editDialog={
                  client && (
                    <ClientEditModal
                      open={openEdit}
                      setOpen={setOpenEdit}
                      client={client}
                    />
                  )
                }
              />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewClients;
