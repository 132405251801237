import React, { useState, useMemo } from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";

import {
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";

import PersonalDetailsInput from "components/clients/inputs/PersonalDetailsInput";
import DocumentsInput from "components/clients/inputs/DocumentsInput";
import PhoneInput from "components/clients/inputs/PhoneInput";
import EmailInput from "components/clients/inputs/EmailInput";
import AddressInput from "components/clients/inputs/AddressInput";
import CompanyInput from "components/clients/inputs/CompanyInput";
import FrequentFlyerInput from "components/clients/inputs/FrequentFlyerInput";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  errorSwal,
  loadingSwal,
  successSwal,
  confirmationSwal,
} from "utils/utils";
import { useAuth } from "contexts/AuthContext";
import SEO from "components/SEO";
import { validateErrors } from "utils/validations/client/globalValidation";
import Detail from "views/viewClients/components/Detail";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import NotesInput from "components/clients/inputs/NotesInput";

const CREATE_CLIENT = gql`
  mutation CreateClient(
    $surname: String!
    $givenName: String!
    $birthdate: Date!
    $documents: [DocumentsInput!]!
    $phones: [PhonesInput!]!
    $emails: [EmailsInput!]
    $address: AddressInput
    $companies: [CompaniesInput!]
    $frequentFlyer: [FrequentFlyerInput!]
    $notes: NotesInput
    $adminId: String!
    $isGlobal: Boolean!
  ) {
    createClient(
      surname: $surname
      givenName: $givenName
      birthdate: $birthdate
      documents: $documents
      phones: $phones
      emails: $emails
      address: $address
      companies: $companies
      frequentFlyer: $frequentFlyer
      notes: $notes
      adminId: $adminId
      isGlobal: $isGlobal
    ) {
      id
      surname
      givenName
      birthdate
      documents {
        docType
        docNum
        docCountry
      }
      phones {
        phoneType
        phoneCountry
        phone
      }
    }
  }
`;

const GET_MATCHES = gql`
  query ClientMatches(
    $givenName: String!
    $surname: String!
    $birthdate: Date!
  ) {
    clientMatches(
      givenName: $givenName
      surname: $surname
      birthdate: $birthdate
    ) {
      id
      surname
      givenName
      birthdate
      documents {
        docType
        docNum
        docCountry
      }
    }
  }
`;

const RegisterClient = () => {
  const history = useHistory();
  const reactSwal = useMemo(() => {
    return withReactContent(Swal);
  }, []);

  const { currentUser } = useAuth();

  const blankError = { hasError: false, message: "" };

  const [isMultiple, setIsMultiple] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    surname: "",
    givenName: "",
    birthdate: null,
  });

  const [personalDetailsError, setPersonalDetailsError] = useState({
    surname: blankError,
    givenName: blankError,
    birthdate: blankError,
  });

  const [documents, setDocuments] = useState([
    { docType: "", docNum: "", docCountry: "", issueDate: null, expDate: null },
  ]);

  const [documentsError, setDocumentsError] = useState([
    {
      docType: blankError,
      docNum: blankError,
      docCountry: blankError,
      issueDate: blankError,
      expDate: blankError,
    },
  ]);

  const [phones, setPhones] = useState([
    { phoneType: "", phoneCountry: "", phone: "" },
  ]);

  const [phonesError, setPhonesError] = useState([
    { phoneType: blankError, phoneCountry: blankError, phone: blankError },
  ]);

  const [emails, setEmails] = useState([{ emailType: "", email: "" }]);
  const [hasEmail, setHasEmail] = useState(false);
  const [emailsError, setEmailsError] = useState([
    { emailType: blankError, email: blankError },
  ]);

  const [address, setAddress] = useState({
    street: "",
    apt: "",
    country: "",
    state: "",
    city: "",
    district: "",
    zipCode: "",
  });

  const [addressError, setAddressError] = useState({
    street: blankError,
    apt: blankError,
    country: blankError,
    state: blankError,
    city: blankError,
    district: blankError,
    zipCode: blankError,
  });

  const [hasAddress, setHasAddress] = useState(false);

  const [companies, setCompanies] = useState([{ company: "", companyId: "" }]);

  const [companiesError, setCompaniesError] = useState([
    { company: blankError, companyId: blankError },
  ]);

  const [hasCompanyAddress, setHasCompanyAddress] = useState([false]);

  const [hasCompany, setHasCompany] = useState(false);

  const [frequentFlyer, setFrequentFlyer] = useState([
    { airline: "", code: "" },
  ]);

  const [frequentFlyerError, setFrequentFlyerError] = useState([
    { airline: blankError, code: blankError },
  ]);

  const [hasFrequentFlyer, setHasFrequentFlyer] = useState(false);

  const [notes, setNotes] = useState({ isPrivate: false, notes: "" });

  const [hasNotes, setHasNotes] = useState(false);

  const [isGlobal, setIsGlobal] = useState(false);

  const clearValues = () => {
    setPersonalDetails({
      surname: "",
      givenName: "",
      birthdate: null,
    });

    setPersonalDetailsError({
      surname: blankError,
      givenName: blankError,
      birthdate: blankError,
    });

    setDocuments([
      {
        docType: "",
        docNum: "",
        docCountry: "",
        issueDate: null,
        expDate: null,
      },
    ]);

    setDocumentsError([
      {
        docType: blankError,
        docNum: blankError,
        docCountry: blankError,
        issueDate: blankError,
        expDate: blankError,
      },
    ]);

    setPhones([{ phoneType: "", phoneCountry: "", phone: "" }]);

    setPhonesError([
      { phoneType: blankError, phoneCountry: blankError, phone: blankError },
    ]);

    setHasEmail(false);
    setEmails([{ emailType: "", email: "" }]);
    setEmailsError([{ emailType: blankError, email: blankError }]);

    setHasAddress(false);
    setAddress({
      street: "",
      apt: "",
      country: "",
      state: "",
      city: "",
      district: "",
      zipCode: "",
    });
    setAddressError({
      street: blankError,
      apt: blankError,
      country: blankError,
      state: blankError,
      city: blankError,
      district: blankError,
      zipCode: blankError,
    });

    setHasCompany(false);
    setCompanies([{ company: "", companyId: "" }]);
    setCompaniesError([{ company: blankError, companyId: blankError }]);

    setHasFrequentFlyer(false);
    setFrequentFlyer([{ airline: "", code: "" }]);
    setFrequentFlyerError([{ airline: blankError, code: blankError }]);

    setHasNotes(false);
    setNotes({ isPrivate: false, notes: "" });

    setIsGlobal(false);
  };

  const [createClient] = useMutation(CREATE_CLIENT, {
    onCompleted: async () => {
      const res = await reactSwal.fire({
        ...successSwal,
        text: "Client added correctly",
      });
      if (res.isConfirmed || res.isDismissed) {
        if (isMultiple) {
          clearValues();
          document.querySelector("main").scrollTop = 0;
        } else {
          history.push("/admin/view-clients");
        }
      }
    },
    onError: (error) => {
      console.log(error);
      reactSwal.fire(errorSwal);
    },
  });

  const clientInfo = {
    personalDetails,
    documents,
    phones,
    emails,
    address,
    companies,
    frequentFlyer,
  };
  const clientError = {
    personalDetailsError,
    documentsError,
    phonesError,
    emailsError,
    addressError,
    companiesError,
    frequentFlyerError,
  };
  const setClientError = {
    setPersonalDetailsError,
    setDocumentsError,
    setPhonesError,
    setEmailsError,
    setAddressError,
    setCompaniesError,
    setFrequentFlyerError,
  };
  const clientFields = {
    hasEmail,
    hasAddress,
    hasCompany,
    hasCompanyAddress,
    hasFrequentFlyer,
  };

  const createNewClient = () => {
    const data = {
      ...personalDetails,
      documents,
      phones,
      adminId: currentUser.id ? currentUser.id : currentUser.uid,
      isGlobal,
    };

    if (hasEmail) data.emails = emails;
    if (hasAddress) data.address = address;
    if (hasCompany) data.companies = companies;
    if (hasFrequentFlyer) data.frequentFlyer = frequentFlyer;
    if (hasNotes) data.notes = notes;

    createClient({ variables: { ...data } });
  };

  const [getClientMatches] = useLazyQuery(GET_MATCHES, {
    onCompleted: async ({ clientMatches }) => {
      console.log(clientMatches);
      if (clientMatches.length > 0) {
        const res = await reactSwal.fire({
          ...confirmationSwal,
          title: "Possible duplicate clients found!",
          html: (
            <div style={{ padding: "1rem", textAlign: "left" }}>
              {clientMatches.map((client, idx) => {
                return (
                  <Paper key={idx} style={{ padding: "0.5rem" }} elevation={3}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={4}>
                        <Detail label="Surname" value={client.surname} />
                      </Grid>
                      <Grid item xs={4}>
                        <Detail label="Given Name" value={client.givenName} />
                      </Grid>
                      <Grid item xs={4}>
                        <Detail
                          label="Birthdate"
                          value={format(
                            new Date(client.birthdate),
                            "MM/dd/yyyy"
                          )}
                        />
                      </Grid>
                    </Grid>
                    {client.documents.map((clientDoc, idx) => {
                      const { docType, docNum, docCountry } = clientDoc;
                      return (
                        <Paper
                          key={idx}
                          style={{
                            marginTop: "1rem",
                            padding: "1rem",
                          }}
                          elevation={3}
                        >
                          <Grid container columnSpacing={2}>
                            <Grid item xs={3}>
                              <Detail label="Doc Type" value={docType} />
                            </Grid>
                            <Grid item xs={3}>
                              <Detail label="Doc N°" value={docNum} />
                            </Grid>
                            <Grid item xs={6}>
                              <Detail label="Country" value={docCountry} />
                            </Grid>
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Paper>
                );
              })}
              <center style={{ marginTop: "1rem" }}>Register anyways?</center>
            </div>
          ),
        });

        if (res.isConfirmed) {
          createNewClient();
        }
      } else {
        createNewClient();
      }
    },
    onError: (error) => {
      reactSwal.fire(errorSwal);
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateErrors(clientInfo, clientError, setClientError, clientFields)) {
      reactSwal.fire({
        ...errorSwal,
        text: "There are fields that have errors",
      });
      return;
    }
    const surname =
      personalDetails.surname.indexOf(" ") === -1
        ? personalDetails.surname
        : personalDetails.surname.substring(
            0,
            personalDetails.surname.indexOf(" ")
          );
    const givenName =
      personalDetails.givenName.indexOf(" ") === -1
        ? personalDetails.givenName
        : personalDetails.givenName.substring(
            0,
            personalDetails.givenName.indexOf(" ")
          );

    const birthdate = personalDetails.birthdate;
    reactSwal.fire(loadingSwal);

    getClientMatches({ variables: { surname, givenName, birthdate } });
  };

  return (
    <>
      <SEO title="Register Client" />
      <center>
        <FormGroup style={{ display: "inline-block", marginBottom: "0.8rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMultiple}
                id="isMultiple"
                onChange={(e) => {
                  const { checked } = e.target;
                  setIsMultiple(checked);
                }}
              />
            }
            label="Register multiple clients."
          />
        </FormGroup>
      </center>
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PersonalDetailsInput
              personalDetails={personalDetails}
              setPersonalDetails={setPersonalDetails}
              personalDetailsError={personalDetailsError}
              setPersonalDetailsError={setPersonalDetailsError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DocumentsInput
              documents={documents}
              setDocuments={setDocuments}
              documentsError={documentsError}
              setDocumentsError={setDocumentsError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              phones={phones}
              setPhones={setPhones}
              phonesError={phonesError}
              setPhonesError={setPhonesError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EmailInput
              emails={emails}
              setEmails={setEmails}
              hasEmail={hasEmail}
              setHasEmail={setHasEmail}
              emailsError={emailsError}
              setEmailsError={setEmailsError}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressInput
              address={address}
              setAddress={setAddress}
              hasAddress={hasAddress}
              setHasAddress={setHasAddress}
              addressError={addressError}
              setAddressError={setAddressError}
            />
          </Grid>
          <Grid item xs={12}>
            <CompanyInput
              companies={companies}
              setCompanies={setCompanies}
              hasCompany={hasCompany}
              setHasCompany={setHasCompany}
              hasCompanyAddress={hasCompanyAddress}
              setHasCompanyAddress={setHasCompanyAddress}
              companiesError={companiesError}
              setCompaniesError={setCompaniesError}
            />
          </Grid>
          <Grid item xs={12}>
            <FrequentFlyerInput
              frequentFlyer={frequentFlyer}
              setFrequentFlyer={setFrequentFlyer}
              hasFrequentFlyer={hasFrequentFlyer}
              setHasFrequentFlyer={setHasFrequentFlyer}
              frequentFlyerError={frequentFlyerError}
              setFrequentFlyerError={setFrequentFlyerError}
            />
          </Grid>
          <Grid item xs={12}>
            <NotesInput
              notes={notes}
              setNotes={setNotes}
              hasNotes={hasNotes}
              setHasNotes={setHasNotes}
            />
          </Grid>
        </Grid>
        <center style={{ marginTop: "2rem" }}>
          <FormGroup
            style={{ display: "inline-block", marginBottom: "0.8rem" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGlobal}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setIsGlobal(checked);
                  }}
                />
              }
              label="Share client with all admins."
            />
          </FormGroup>
          <br />
          <Button size="large" type="submit" variant="contained">
            Register
          </Button>
        </center>
      </form>
    </>
  );
};

export default RegisterClient;
