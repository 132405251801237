import validator from "validator";

export const validateField = (setFunction, field, value, docType) => {
  let error = {
    hasError: false,
    message: "",
  };

  if (value === "" || null) {
    error = {
      hasError: true,
      message: "This field is required.",
    };
  } else {
    switch (field) {
      case "surname":
      case "givenName":
        const fieldName = field === "surname" ? "Surname" : "Given Name";
        if (
          !validator.isAlpha(value, "es-ES", {
            ignore: " ",
          }) ||
          !validator.isLength(value, {
            min: 2,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid ${fieldName} is requiered.`,
          };
        }
        break;
      case "docNum":
        if (
          !validator.isAlphanumeric(value) ||
          !validator.isLength(value, {
            min: 8,
          }) ||
          !validator.isNumeric(value)
        ) {
          error = {
            hasError: true,
            message: `A valid document number is required.`,
          };
        }
        break;
      case "address":
        if (
          !validator.isAlpha(value, "es-ES", {
            ignore: " ,.-0123456789",
          }) ||
          !validator.isLength(value, {
            min: 5,
          })
        ) {
          error = {
            hasError: true,
            message: `A valid address is required.`,
          };
        }
        break;
      case "email":
        if (!validator.isEmail(value)) {
          error = {
            hasError: true,
            message: `A valid email address is required.`,
          };
        }
        break;
      case "phone":
        if (!validator.isMobilePhone(value, "any")) {
          error = {
            hasError: true,
            message: `A valid phone number is required.`,
          };
        }
        break;
      case "password":
      case "confirmPassword":
        if (
          !validator.isLength(value, {
            min: 8,
          })
        ) {
          error = {
            hasError: true,
            message: `Password requires minimum 8 characters.`,
          };
        }
        break;
      default:
        break;
    }
  }

  setFunction((prevVal) => {
    return {
      ...prevVal,
      [field + "Error"]: error,
    };
  });
};

export const validateFields = (personalInfo, errors, setFunction) => {
  let hasError = false;

  for (const field in personalInfo) {
    if (personalInfo[field] === "") {
      hasError = true;
      setFunction((prevVal) => {
        return {
          ...prevVal,
          [field + "Error"]: {
            hasError: true,
            message: "This field is required.",
          },
        };
      });
    }
  }

  for (const field in errors) {
    if (errors[field].hasError) hasError = true;
  }

  return hasError;
};
